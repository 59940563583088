import React, { useState, useEffect } from 'react';
import Copyright from '../Copyright';
import { Container, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import httpRequest from '../HttpService';
import PageBanner from '../orderStatus/components/pageBanner';
import { Button} from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ArrowForward';
import "../orderStatus/orderDetail.css";
import { useNavigate } from 'react-router-dom';


export default function Preferences(props)  {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [address, setAddress] = useState({});
    const [preferences, setPreferences] = useState({});

    const IsEmbbed = localStorage.getItem('IsEmbedded')!==null && localStorage.getItem('IsEmbedded')==='yes';
    const token = localStorage.getItem('sso_token');

    useEffect(() => {  
        httpRequest.postLoginToken(token).then(async (body) =>{
            console.log("postLoginToken return-" + JSON.stringify(body));
            setUser(body.data.user);
            setPreferences(body.data.preferences);            
        }); 
        setAddress(JSON.parse(localStorage.getItem('userAddress')));     
    },[])

       
    const handleLogout = () => {
        localStorage.clear();  
        navigate('/'); 
        // window.location.href='/';  
    }
 
    return (
        <Container maxWidth="lg" class="NewOrder">   
            <div id="bannerBackground">&nbsp;</div>
            <PageBanner title={address.companyName} subheading={"#"+ user.accountNumber} />    
            <div style={{ margin: "10% 5% 5% 15%" }}>           
            <Grid container height="100vh" display="flex" rowSpacing={0}   style={{marginLeft: 350}}>
                <Grid item md={2} style={{height: 80}}>
                    <Typography style={{ fontWeight: 600 }}>Address</Typography>
                </Grid>
                <Grid item md={10}>
                    <p>{ address.hasOwnProperty('suite') && address.suite && <Typography style={{ fontWeight: 400 }}>Suite {address.suite},</Typography>} <Typography style={{ fontWeight: 400 }}>{address.address}, {address.quad}</Typography></p>
                    <p> <Typography style={{ fontWeight: 400 }}>{address.city} </Typography> { address.hasOwnProperty('prov') &&  <Typography style={{ fontWeight: 400 }}>{address.prov}</Typography>} { address.hasOwnProperty('country') &&  <Typography style={{ fontWeight: 400 }}>,{address.country}</Typography>}</p>
                    <p>{address.postal}</p>                 
                </Grid>
                <Grid item md={2} style={{height: 80}}>
                    <Typography style={{ fontWeight: 600 }}>Phone</Typography>
                </Grid>
                <Grid item md={10}>
                    <Typography style={{ fontWeight: 400 }}>{address.phone}</Typography>
                </Grid>
                <Grid item md={2} style={{height: 80}}>
                    <Typography style={{ fontWeight: 600 }}>Openning Time</Typography>
                </Grid>
                <Grid item md={10}>
                   <Typography style={{ fontWeight: 400 }}>{preferences.opentime}-{preferences.closeTime}</Typography>
                </Grid> 
                <Grid item md={12}>
                    <Button
                        style={{maxWidth:"300px", minWidth:"200px", float:"center"}}
                        variant="contained"                           
                        color="primary" 
                        startIcon={<LogoutIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)'}}/>}
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                </Grid>
            </Grid>  
            </div>    
            {!IsEmbbed && <footer><Copyright/></footer>}
        </Container>
    ); 

}
