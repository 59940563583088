import React, { useState, useEffect } from 'react';
import { format, parseISO } from "date-fns";
import "devextreme/dist/css/dx.light.css";
import httpRequest from '../HttpService';
import OrdersDevExpressDataGrid from '../components/OrdersDevExpressDataGrid';

export default function OrdersList(props){
  const [orders, setOrders] = useState([]); 

  const locationToString = new Map(
      [
      [-1, "All"],
      [1, "At Client"],
      [2, "On Driver"],
      [4, "In Sort"],
      [5, "Rotation Sort"],
      [8, "Delivered"]]
  );
  const statusToString = new Map(
    [
      [-1, "All"],
      [1, "New/Review"],
      [2, "Avail for Dispatch"],
      [3, "Driver Pending"],
      [4, "Driver Responsbility"],
      [8, "Delivered"],
      [16, "Verified"],
      [32, "Invoiced"]
    ]
  );

  const dateFormat = 'yy-MM-dd HH:mm'

  useEffect(() => {      
    console.log("OrderList useEffect- getOrders" + props.account);
    getOrder();
    const interval = setInterval(() => {
      getOrder()
    }, 60000);
    return () => clearInterval(interval);
  },[])

  const getOrder= () =>{  
    httpRequest.getOrders(props.account).then(async (body) =>{
      let _orderList =  body.data.map((value,index) => {
        value.id = index;
        value.Status = statusToString.get(value.Status);
        value.location = locationToString.get(value.location);
        value.dtCallIn_UTC =!value.dtCallIn_UTC ? '' : format(parseISO(value.dtCallIn_UTC), dateFormat);
        value.dtDCT_UTC =!value.dtDCT_UTC ? '' : format(parseISO(value.dtDCT_UTC), dateFormat);
        value.dtDelivered_UTC = !value.dtDelivered_UTC ? '' : format(parseISO(value.dtDelivered_UTC), dateFormat);
        return value;
      });
      setOrders(_orderList);
    });    
  }

  return(
    <OrdersDevExpressDataGrid orders={orders} account={props.account} listType="orderStatus"/>
  )
}