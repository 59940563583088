import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import InboxIcon from '@material-ui/icons/Inbox';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Link from '@material-ui/core/Link';
import { palette } from './palette';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import { SvgIcon } from '@material-ui/core';
import logo from './logo.svg';
import 'animate.css/animate.css';
import {NavLink,useNavigate} from 'react-router-dom';


  const MNavLink = withStyles({
    root: {
        color: (props) => props.selected ? 'black' : 'grey',
        textDecoration: 'none',
        '&:hover': {
            color: 'black',
            textDecoration: 'none',
        }
    }
  })(Link);


// https://material-ui.com/components/app-bar/#app-bar-with-a-primary-search-field

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    logoButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'block',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
        display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
        display: 'none',
        },
    },
}));

export default function NavigationBar(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        console.log("handleProfileMenuOpen");
        setAnchorEl(event.currentTarget);
        navigate(`/preferences`);     
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {       
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const pathname = useLocation().pathname;
    console.log("NavigationBar", pathname);
    if(!pathname.includes("orderDetail") && !pathname.includes("history"))
        localStorage.setItem('searchHistory', 'no'); 


    const menuId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/neworder" selected={pathname === '/neworder'}>
                New Order
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/orderstatus" selected={pathname === '/orderstatus'}>
                Order Status
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/history" selected={pathname === '/history'}>
                History
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/invoices" selected={pathname === '/invoices'}>
                Invoices
            </MNavLink>
        </MenuItem>}
        {props.isLoggedIn && <MenuItem>
            <MNavLink href="/faq" color="inherit" selected={pathname === '/faq'}>
                FAQ & More
            </MNavLink>
        </MenuItem>}
        {!props.isLoggedIn && <MenuItem>
            <MNavLink href="/" selected={pathname === '/'}>
                Generate Token
            </MNavLink>
        </MenuItem>}        
        {!props.isLoggedIn && <MenuItem>
            <MNavLink href="/tracking" selected={pathname === '/tracking'}>
               Tracking
            </MNavLink>
        </MenuItem>} 
        {/* <MenuItem>
            <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="secondary">
                <MailIcon />
            </Badge>
            </IconButton>
            <p>Messages</p>
        </MenuItem>
        <MenuItem>
            <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge badgeContent={11} color="secondary">
                <NotificationsIcon />
            </Badge>
            </IconButton>
            <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"            
            >
            <AccountCircle />
            </IconButton>
            <p>Profile</p>
        </MenuItem> */}
    </Menu>
  );

    //https://material-ui.com/components/drawers/#swipeable
    const renderMenuDrawer = ( <div/>
    // <Drawer anchor='top' open={drawerOpen} onClose={setDrawerOpen(false)}>
    //     <div
    //     style={{
    //         anchor: 'top',
    //         width: 'auto',
    //     }}
    //     role="presentation"
    //     onClick={setDrawerOpen(false)}
    //     onKeyDown={setDrawerOpen(false)}
    //     >
    //         <List>
    //             <ListItem button key="test">
    //                 <Typography variant="body1">Test</Typography>
    //             </ListItem>
    //         </List>
    //     </div>
    // </Drawer>
    );

    

  return (
    <div className={classes.grow}>
        <AppBar position="sticky" style={{background: palette.greys.dark}}>
            <Toolbar>
                <IconButton
                edge="start"
                className={classes.logoButton}
                color="inherit"
                href="/#"
                >
                    <img src={logo} alt="Direct IT"/>
                </IconButton>
                <Grid>

                </Grid>
                {props.isLoggedIn &&
                <div className={classes.sectionDesktop}>
                    <NavLink to="/neworder" selected={pathname === '/neworder'}>
                        New Order
                    </NavLink>
                    <p>&emsp;</p>
                    <NavLink to="/orderstatus" selected={pathname === '/orderstatus'}>
                        Order Status
                    </NavLink>
                    <p>&emsp;</p>
                    <NavLink to="/history" selected={pathname === '/history'}>
                        History
                    </NavLink>
                    <p>&emsp;</p>
                    <NavLink to="/invoices" selected={pathname === '/invoices'}>
                        Invoices
                    </NavLink>
                    <p>&emsp;</p>
                    <NavLink to="/faq" selected={pathname === '/faq'}>
                         FAQ & More
                    </NavLink>
                </div>}

                {!props.isLoggedIn &&
                <div className={classes.sectionDesktop}>
                    <NavLink to="/" selected={pathname === '/'}>    
                        Generate Token
                    </NavLink>
                    <p>&emsp;</p>
                    <NavLink to="/tracking" selected={pathname === '/tracking'}>
                        Tracking
                    </NavLink>
                </div>}
                
                <div className={classes.grow} />
                {props.isLoggedIn &&
                <div className={classes.sectionDesktop}>
                <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                >
                <AccountCircle />
                </IconButton>
                {/* <IconButton color="inherit">
                <Badge badgeContent={4} color="secondary">
                    <div class="animate__animated animate__swing animate__delay-2s animate__slow animate__infinite">
                        <NotificationsIcon/>
                    </div>
                </Badge>    
                </IconButton>             */}
            </div>}

            <div className={classes.sectionMobile}>
                <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                >
                <MoreIcon />
                </IconButton>
            </div>
            </Toolbar>
        </AppBar>
        {renderMobileMenu} 
    </div>
  );
}
