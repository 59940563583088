import React,  {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import  pjson from "../package.json";
import NoticeEmitter from './NoticeUtil.js';

function Copyright() {
  const [apiver, setApiver] = useState(localStorage.getItem("APIVersion", "1.8.30") !== undefined? localStorage.getItem("APIVersion", "1.8.30"): "1.8.30");
 
  NoticeEmitter.addListener('getAPIVersion', (data) => {
    setApiver(data);
  });
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        DirectIT
        {' '}
        {new Date().getFullYear()}
        {'.  ver'}
        {pjson.version}
        {'   APIs ver'}
        {apiver}
      </Typography>
    );
}

export default Copyright;