import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomInput from '../inputFields/CustomInput';
import SelectInput_ORG from '../inputFields/SelectInput_org';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const ReferenceFields = props => {
    
    const [editReferences, setEditReferences] = useState([]);
    const [checkboxReferences, setCheckboxReferences] = useState([]);
    const [dropdownReferences, setDropdownReferences] = useState([]);
    
    useEffect(() => {   
        let editList = [];
        let checkboxList =[];
        let dropdownList = [];
        console.log("referenceField", JSON.stringify(props.referenceList))
        props.referenceList.forEach((item) => {           
            switch(item.typeDesc) {
                case "CheckBox":
                    //var IsChecked = row.defaultValue === '1';
                    item.id= checkboxList.length;
                    checkboxList.push(item);                
                    break;
                case "DropDown":
                    item.id= dropdownList.length;
                    dropdownList.push(item);               
                    break;
                case "Edit":
                    item.id= editList.length;
                    editList.push(item);  
                    break;
                default:
                    break;
            }
        });  
        setEditReferences(editList); 
        setCheckboxReferences(checkboxList);
        setDropdownReferences(dropdownList);
    },[props.referenceList]);


    const clearValue = (event) => {
        console.log(event.target.value);
    }


    const handleRefValueChange = (event) => {
        console.log(event.target.name - "-handleRefValueChange-" + event.target.value); 
        props.setRefValue(event.target.name,event.target.value);      
    }   

    const renderEditField = (editField) => {
        return (
            <Grid item sm={12}>    
                <CustomInput
                    type="text"
                    icon="ClearIcon"
                    inputType="text"
                    label={editField.label}
                    name={editField.refID}
                    id={editField.refID}
                    labelSize = {4}
                    defaultValue = {editField.defaultValue}
                    inputLength = {50}
                    iconTooltip="Clear"
                    onIconClick={(event) => {
                        document.getElementById(editField.refID).value = "";
                        props.setRefValue(editField.refID,"");      
                    }}
                    onBlur={handleRefValueChange}
                />                          
            </Grid>
        )
    }

    const renderDropdownField = (dropdownField) => {
        return (
            <Grid item sm={12}>
                {/* <Typography variant="button" color="primary" style={{fontSize:14, fontWeight: 500}}>{dropdownField.label}</Typography> */}
                {/* <SelectInput_ORG
                    optionList = {dropdownField.ddValues}
                    value = {dropdownField.defaultValue}
                    onChange = {handleRefValueChange}
                    label = {dropdownField.label}
                    labelSize = {3}
                    color={props.color}
                    {...props}/> */}               
                <div>
                <Typography variant = 'h6' color="primary">{dropdownField.label}</Typography>
                <FormControl variant="outlined" style={{width: '100%', maxWidth: 800}}>
                <Select
                   name = {dropdownField.refID}
                   value={dropdownField.defaultValue}
                   style={{ height: 40 }}
                   onChange={(event)=>{
                       dropdownField.defaultValue = event.target.value;
                       handleRefValueChange(event);
                    }}
                    >
                    {dropdownField.ddValues.map((item) => (
                        <MenuItem value={item.ddvalue}>{item.ddvalue}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            </div>
            </Grid>
        )
    }

    const renderCheckboxField = (checkboxField) => {
        return (
            <Grid item sm={12}>
                <FormControlLabel
                    control = {
                        <Checkbox name = {checkboxField.refID}  color = "primary"
                        checked={checkboxField.defaultValue}
                            onChange = {(event) => {
                               checkboxField.defaultValue= event.target.checked;                           
                               props.setRefValue(event.target.name,event.target.checked);                              
                            }
                        }/>                                     
                    }
                    label={<Typography style = {{fontSize:14, fontWeight: 500}} variant="button" color="primary"> {checkboxField.label}</Typography>}
                />      
            </Grid>
        )
    }
  
    return (
        <Grid container direction='row' spacing={2}>
               {editReferences.map((item) => (
                  renderEditField(item)
               ))}
               {checkboxReferences.map((item) => (
                  renderCheckboxField(item)
               ))}
               {dropdownReferences.map((item) => (
                  renderDropdownField(item)
               ))}             
        </Grid> 
    );

};
export default ReferenceFields;
