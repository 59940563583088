import { Divider, Typography } from '@material-ui/core';
import React from 'react';
import { palette } from '../../../palette';
import { theme } from '../../theme';
import styles from './styles';
const OrderDetailsBox = (props) => {

    return (
        <div style={styles.root}>
            <Typography style={styles.orderDetailsTitle}> {props.title} </Typography>
            <Divider style={{color: palette.primary.main}} />
            {props.bold ? 
            <Typography style={{fontSize: theme.sizes.large, fontWeight: 'bold'}}> {props.value} </Typography>
            :
            <Typography style={{fontSize: theme.sizes.large}}> {props.value} </Typography>
            }


        </div>
    );
};

export default OrderDetailsBox;