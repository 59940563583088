import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import TextInput from '../inputFields/TextInput';
import CustomIconButton from '../inputFields/CustomIconButton';

const ContactForm = props => {

    const [nameValue, setNameValue] = useState(props.data.contactName);
    const [phoneValue, setPhoneValue] = useState(props.data.contactPhone);
    const [nameID, setNameID] = useState(props.data.addressID + "-contactName");
    const [phoneID, setPhoneID] = useState(props.data.addressID + "-contactPhone");
    
    const handlerClearClick = (event) => {
        console.log("clear contact--" + JSON.stringify(props.data)); 
        document.getElementById(nameID).value = "";
        document.getElementById(phoneID).value = "";         

        setPhoneValue('');
        setNameValue('');
        props.data.contactName = "";
        props.data.contactPhone = "";         
        props.onChangeContact({
            target: {
                name: props.type === 'shipper'? 'shContactPhone':'coContactPhone',
                value: ""
            }
        });
        props.onChangeContact({
            target: {
                name: props.type === 'shipper'? 'shContactName':'coContactName',
                value: ""
            }
        });
        
    };

    function addDashes(f){
        const f_val = f.replace(/\D[^\.]/g, "");
        if(f_val.length <= 3) {
            return f_val;
        }
        if (f_val.length <= 6) {
            return f_val.slice(0,3)+"-"+f_val.slice(3,6);
        }
        return f_val.slice(0,3)+"-"+f_val.slice(3,6)+"-"+f_val.slice(6);
    }

    const processPhoneNumber = (event) => {
        const formattedPhone = addDashes(event.target.value);
        setPhoneValue(formattedPhone);
        props.data.contactPhone = formattedPhone;
        console.log(props.data);
        event.target.value=formattedPhone;

        props.onChangeContact({
            target: {
                name: props.type === 'shipper'? 'shContactPhone':'coContactPhone',
                value: formattedPhone
            }
        });
    }
    return (
        <Grid container style={{paddingBottom: '2%'}} key='contact-form'>
            <p>CONTACT DETAILS</p>
            <Grid container spacing={1}>
                <Grid item sm={10} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12} key='contact-form-name'>
                            <TextInput                                
                                name="contactName"
                                id = {nameID}
                                type="name"
                                label="Name"
                                labelSize={5}
                                variant="outlined" 
                                size="small" 
                                onChange={(e)=>{
                                    setNameValue(e.target.value);
                                    props.data.contactName = e.target.value;
                                    props.onChangeContact({
                                        target: {
                                            name: props.type === 'shipper'? 'shContactName':'coContactName',
                                            value: e.target.value
                                        }
                                    });
                                }}
                                // onBlur={(e)=>{
                                //     setNameValue(e.target.value);
                                //     //props.data.contactName = e.target.value;
                                //     props.onChangeContact({
                                //         target: {
                                //             name: props.type === 'shipper'? 'shContactName':'coContactName',
                                //             value: e.target.value
                                //         }
                                //     });
                                // }}
                                defaultValue={nameValue}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12} key='contact-form-phone'>
                            <TextInput 
                                name="contactPhone"
                                id = {phoneID}
                                type="tel"
                                label="Phone"
                                labelSize={5}
                                variant="outlined" 
                                size="small" 
                                onChange={(e)=>{                                   
                                    if(e.target.value.length>=10){
                                       processPhoneNumber(e)
                                    }
                                }}
                               // onBlur={processPhoneNumber}
                                defaultValue={phoneValue}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={2}>
                    <CustomIconButton icon="CloseSharpIcon" tooltip="Clear Contact" label="Clear" onClick={handlerClearClick} variant="outlined" color="primary" selected={true}/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ContactForm;