import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { Tooltip, Grid } from "@material-ui/core";
import CustomInput from "../inputFields/CustomInput";
import HttpRequest from "../../../HttpService";
import CallerInput from "./CallerInput";
import ReferenceFields from "./ReferenceFields";
import TextInput from "../../components/inputFields/TextInput";
import TextField from "@material-ui/core/TextField";

import InstructionInput from "./InstructionInput";

function PackageOptionsDetails(props) {
  const [name, setName] = useState("");
  //const [copiedName, setCopiedName] = useState('');
  const [newUser, setNewUser] = useState(false);
  const [emailNotif, setEmailNotif] = useState(false);
  const [deliveryEmail, setDeliveryEmail] = useState("");
  const [signatureRequired, setSignatureRequired] = useState(true);
  const [pictureRequired, setPictureRequired] = useState(false);
  const [declaredValue, setDeclaredValue] = useState(false);
  const [names, setNames] = useState([]);
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [defaultInstructions, setDefaultInstructions] = useState("");


  const handleSelectName = (event) => {
    console.log("handleSelectName-" + event.target.value);
    setName(event.target.value);
    props.onChange(event);
  };

  const handleAddPerson = (event) => {
    setNewUser(!newUser);
    props.onChange({
      target: {
        name: "IsNewCaller",
        value: !newUser,
      },
    });
  };

  const handleSavePerson = (event) => {
    if (name !== "") {
      handleAddPerson(event);
      var newCaller = {
        username: name,
        emailAddress: deliveryEmail,
      };
      HttpRequest.postUserEmails(props.accountNumber, newCaller).then(
        async function (body) {
          HttpRequest.getUserEmails(props.accountNumber).then(async function (
            body
          ) {
            const nameList = [];
            await body.data.forEach((person) => {
              nameList.push({ value: person.username, label: person.username });
              if (person.username === name) {
                setPrimaryEmail(person.emailAddress);
                setEmailNotif(false);
                setEmailNotif(emailNotif);

                props.onChange({
                  target: {
                    name: "deliveryEmail",
                    value: person.emailAddress,
                  },
                });
              }
            });
            setNames(nameList);
          });
        }
      );
    }
  };

  const handleRemovePerson = (event) => {
    console.log("handleRemovePerson-" + name);
    HttpRequest.removeCaller(props.accountNumber, name).then(async function (
      body
    ) {
      console.log("removeCaller-" + JSON.stringify(body.data));
      let nameList = names.filter(function (obj) {
        return obj.username !== name;
      });
      setNames(nameList);
      props.onChange({
        target: {
          name: "name",
          value: "select",
        },
      });
      setName("");
    });
  };

  const handleSendEmail = (event) => {
    props.onChange({
      target: {
        name: "checkedEmail",
        value: !emailNotif,
      },
    });
    setEmailNotif(!emailNotif);
  };

  const handleDeliveryEmail = (event) => {
    props.onChange(event);
    setDeliveryEmail(event.target.value);
  };

  const handleSignatureRequired = (event) => {
    props.onChange({
      target: {
        name: "signatureRequired",
        value: !signatureRequired,
      },
    });
    setSignatureRequired(!signatureRequired);
  };

  const handlePictureRequired = (event) => {
    props.onChange({
      target: {
        name: "pictureRequired",
        value: !pictureRequired,
      },
    });
    setPictureRequired(!pictureRequired);
  };

  const handleDeclaredValue = (event) => {
    setDeclaredValue(!declaredValue);
  };

  const clearValue = (event) => {
    console.log(event.target.value);
  };

  useEffect(() => {
    const nameList = [];
    async function getNames() {
      HttpRequest.getUserEmails(props.accountNumber).then(async (body) => {
        await body.data.forEach((person) => {
          nameList.push({ value: person.username, label: person.username });
        });
        setNames(nameList);

        const pre_orderStr = localStorage.getItem("previousOrder");
        if (pre_orderStr && pre_orderStr !== "") {
          console.log("retrieved previousOrder is " + pre_orderStr);
          const pre_order = JSON.parse(pre_orderStr);

          console.log("retrieved pre_order.checkedEmail is " + pre_order.checkedEmail);
          setEmailNotif(pre_order.checkedEmail);
          props.onChange({
            target: {
              name: "checkedEmail",
              value: pre_order.checkedEmail,
            },
          });
          
          console.log("retrieved previousOrder name is " + pre_order.caller);
          setName(pre_order.caller);
          props.onChange({
            target: {
              name: "name",
              value: pre_order.caller,
            },
          });
          // setDefaultInstructions(pre_order.instructions);
          // props.onChange({
          //   target: {
          //     name: "instructions",
          //     value: pre_order.instructions,
          //   },
          // });
          
        }
      });
    }
    getNames();
  }, [props.accountNumber]);

  useEffect(() => {
    async function getEmail() {
      HttpRequest.getUserEmails(props.accountNumber).then(async function (
        body
      ) {
        await body.data.forEach((person) => {
          if (person.username === name) {
            setPrimaryEmail(person.emailAddress);
            setEmailNotif(false);
            setEmailNotif(emailNotif);

            props.onChange({
              target: {
                name: "deliveryEmail",
                value: person.emailAddress,
              },
            });
          }
        });
      });
    }
    getEmail();
  }, [name]);

  function renderPickName() {
    return (
      <CallerInput
        label="Name"
        inputType="select"
        iconOne="AddPersonIcon"
        iconTwo="removePersonIcon"
        optionList={names}
        name="name"
        value={name}
        icononetooltip="Add new"
        icontwotooltip="remove"
        onChange={handleSelectName}
        onIconOneClick={handleAddPerson}
        onIconTwoClick={handleRemovePerson}
        labelSize={3}
      />
    );
  }

  function renderAddPerson() {
    return (
      <CallerInput
        type="name"
        iconOne="ListIcon"
        iconTwo="saveIcon"
        inputType="text"
        inputLength={20}
        label="Name"
        name="name"
        icononetooltip="Select from list"
        iconTwoTooltip="Save"
        onChange={handleSelectName}
        onIconOneClick={handleAddPerson}
        onIconTwoClick={handleSavePerson}
        labelSize={3}
      />
    );
  }

  /*
    function renderAddPerson(){
        return (
            <CustomInput
                type="name"
                icon="ListIcon"
                inputType="text"
                label="Name"
                name = "name"
                iconTooltip="Select from list"
                onChange = {handleSelectName}
                onIconClick={handleAddPerson}
                labelSize = {3}
            />
        );
    }
    */

  function rendAccountServiceNotes() {
    let notes = "";
    props.accountServiceNotes.map((item) => {
      if (item.Notes !== null && item.Notes !== "") notes += item.Notes + ";";
    });
    console.log("rendAccountServiceNotes is" + notes);
    return notes !== "" ? (
      <div>
        {/* <TextInput
                    inputType="text" name="accountNotes" multiline  minRows={1} hideLabel={true}
                    
                    disabled
                    style={{backgroundColor: '#fff3cd'}}
                 /> */}
        <TextField
          editable={false}
          variant="outlined"
          size="small"
          style={{ backgroundColor: "#fff3cd" }}
          fullWidth
          multiline
          disabled
          label=""
          value={notes}
        />
      </div>
    ) : (
      <></>
    );
  }

  function renderEmailAddresses() {
    return (
      <div>
        <CustomInput
          type="email"
          icon="ClearIcon"
          inputType="text"
          label="Delivery Email"
          name="deliveryEmail"
          iconTooltip="Clear"
          onIconClick={(event) => {
            document.getElementById("deliveryEmail").value = "";
          }}
          labelSize={3}
          defaultValue={primaryEmail}
          onBlur={handleDeliveryEmail}
        />
        <br />
        <CustomInput
          type="email"
          icon="ClearIcon"
          inputType="text"
          label="Additional Email"
          name="additionalEmail"
          iconTooltip="Clear"
          onIconClick={(event) => {
            document.getElementById("additionalEmail").value = "";
          }}
          labelSize={4}
          onBlur={props.onChange}
        />
        <br />
      </div>
    );
  }

  function renderDeclaredValue() {
    return (
      <div>
        <CustomInput
          type="number"
          icon="ClearIcon"
          inputLength={5}
          inputType="number"
          label="$"
          name="declaredValue"
          iconTooltip="Clear"
          onIconClick={(event) => {
            document.getElementById("declaredValue").value = "";
          }}
          labelSize={1}
          onBlur={props.onChange}
        />
        <br />
      </div>
    );
  }

  return (
    <div style={{ align: "left" }}>
      <Typography variant="h6" color="primary">
        Your Info
      </Typography>
      <Typography
        variant="body1"
        color="primary"
        style={{ fontWeight: "bold" }}
      >
        Name
      </Typography>

      <Tooltip title="Required" placement="top" arrow>
        <div>
          {!newUser && renderPickName()}
          {newUser && renderAddPerson()}
        </div>
      </Tooltip>

      <FormControlLabel
        control={
          <Checkbox
            checked={emailNotif}
            onChange={handleSendEmail}
            color="primary"
          />
        }
        label="EMAIL DELIVERY NOTIFICATION"
      />
      {emailNotif && renderEmailAddresses()}
      {/* <CustomInput
                        type="textarea"
                        icon="ClearIcon"
                        inputType="text"
                        inputLength = {200}
                        multiline
                        label="Instruction"
                        name="instructions"
                        defaultValue = {defaultInstructions}
                        iconTooltip="Clear"
                        onIconClick={(event) => {
                            document.getElementById('instructions').value = "";
                        }}
                        labelSize = {3}
                        onBlur={props.onChange}
                /> */}
      <InstructionInput
        type="textarea"
        iconOne="ClearIcon"
        inputType="text"
        inputLength={200}
        multiline
        label="Instruction"
        name="instructions"
        defaultValue={defaultInstructions}
        icononetooltip="Clear"
        onIconOneClick={(event) => {
          document.getElementById("instructions").value = "";
        }}
        labelSize={3}
        onBlur={props.onChange}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={!signatureRequired}
            onClick={handleSignatureRequired}
            color="primary"
          />
        }
        label="No Signature Required"
      />
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={pictureRequired}
            onClick={handlePictureRequired}
            color="primary"
          />
        }
        label="PICTURED REQUIRED"
      /> */}

      {/* <div> */}
      <ReferenceFields
        referenceList={props.referenceList}
        setRefValue={props.setRefValue}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={declaredValue}
            onClick={handleDeclaredValue}
            color="primary"
          />
        }
        label="DECLARED VALUE"
      />
      {declaredValue && renderDeclaredValue()}
      {/* </div> */}

      {/* <div>                    
                    <Button variant="contained"  color="primary"
                            endIcon={<KeyboardArrowRightIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)'}}/>}          
                            onClick={props.nextPanel}
                    >
                         Next
                     </Button>          
                </div> 
                {props.accountServiceNotes.length>0 && rendAccountServiceNotes()} */}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={0}
      >
        <Grid item md={2}>
          <Button
            variant="contained"
            color="primary"
            endIcon={
              <KeyboardArrowRightIcon
                style={{ verticalAlign: "middle", transform: "scale(1.5)" }}
              />
            }
            onClick={props.nextPanel}
          >
            Next
          </Button>
        </Grid>
        <Grid item md={10}>
          {props.accountServiceNotes.length > 0 && rendAccountServiceNotes()}
        </Grid>
      </Grid>
    </div>
  );
}

export default PackageOptionsDetails;
