import React,{useEffect, useState} from "react";
//import ReactToPrint from "react-to-print";
import httpRequest from '../../../HttpService';
import Barcode from 'react-barcode';
import './printPanel.css'
import logo from '../../../directitgroup_icon.png';


class PrintPanel extends React.Component {
  constructor(props) {
      super(props);  
      this.state = {
        //account: localStorage.getItem('accountNumber'), 
        account: props.account,  
        id: props.id,
        order:{},  
      }
  }

  componentDidMount = () => {
      httpRequest.getOrder(this.state.account, this.state.id).then(response => {
        var _order = response.data;
        if(_order.dtDelivered)
            _order.dtDelivered_display = _order.dtDelivered.substring(0,19).replace("T"," ");
        if(_order.dtCallIn)  
            _order.dtCallIn_display = _order.dtCallIn.substring(0,19).replace("T"," "); 
        if(_order.dtDCT)
            _order.dtDCT_display = _order.dtDCT.substring(0,19).replace("T"," ");  
          this.setState({
              order : _order             
          })
      });
  }

  renderFromAddress(){
      let suite = "";
      if(this.state.order.sSuite){
          suite = this.state.order.sSuite+", ";
      }
      return suite+this.state.order.sAddress+" "+this.state.order.sQuad
              +", "+this.state.order.sCity+", "+this.state.order.sProv+", "+this.state.order.sPostal
              +", "+this.state.order.sCountry;
  }

  renderToAddress(){
      let suite = "";
      if(this.state.order.cSuite){
          suite = this.state.order.cSuite+", ";
      }
      return suite+this.state.order.cAddress+" "+this.state.order.cQuad
          +", "+this.state.order.cCity+", "+this.state.order.cProv+", "+this.state.order.cPostal
          +", "+this.state.order.cCountry;
  }

  render() {
      return (
      <div>
         <img src={logo} alt="Direct-IT Group Courier" style={{ width: "20%", marginTop: 20,marginLeft: 15}}/>
         <table style={{marginLeft: 25}}>
              <tr>
                  <th className="td_head_title">                       
                    <strong className="titlefont"># {this.state.id}</strong>
                  </th>
                  <td>
                    <Barcode value={this.state.id}/>
                  </td>
              </tr>
         </table>  
         <table className="printTable">             
          <tr>
             <th className="td_title"> <strong className="printfont">Your Name & Phone</strong></th>
             <td className="black td_content"> <strong className="printfont">{this.state.order.CALLER}</strong></td>
          </tr>
          <tr>
             <th className="td_title"><strong className="printfont">Due by</strong></th>
             <td className="black td_content"> <strong className="printfont">{this.state.order.dtDCT_display + " " + this.state.order.timezone}</strong></td>
          </tr>
          <tr>   
             <th className="td_title"><strong className="printfont">Service Type & Minutes</strong></th>
             <td className="black td_content"> <strong className="printfont"><span>{this.state.order.SER_AREA}-{this.state.order.SER_TIME}</span></strong></td>
          </tr>
          <tr>
             <th className="td_title"><strong className="printfont">Call In</strong></th>             
             <td className="black td_content"> <strong className="printfont">{this.state.order.dtCallIn_display + " " + this.state.order.timezone}</strong></td>
          </tr>
          <tr>
            <th className="td_title"><strong className="printfont">From</strong></th>
            <td className="black td_content"> <strong className="printfont">{this.state.order.sCompanyName}</strong></td>
          </tr>
          <tr>   
            <th className="td_title"> <strong className="printfont">From Address</strong></th>
            <td className="black td_content"> <strong className="printfont"><span>{this.renderFromAddress()}</span></strong></td>
          </tr>
          <tr>
            <th className="td_title"> <strong className="printfont">To</strong></th>
            <td className="black td_content"><strong className="printfont">{this.state.order.cCompanyName}</strong></td>
          </tr>
          <tr>   
            <th className="td_title"> <strong claclassNamess="printfont">To Address</strong></th>
            <td className="black td_content"><strong className="printfont"><span>{this.renderToAddress()}</span></strong></td>
          </tr>
          <tr>
            <th className="td_title"> <strong className="printfont">Pieces</strong></th>
            <td className="black td_content"><strong className="printfont">{this.state.order.PIECES} pieces </strong></td>
          </tr>
          <tr>   
            <th className="td_title"><strong className="printfont">Weight</strong></th>
            <td className="black td_content"><strong className="printfont">{this.state.order.WEIGHT} lbs</strong></td>
          </tr>
          <tr>   
            <th className="td_title"><strong className="printfont">Reference</strong></th>
            <td className="black td_content"><strong className="printfont">{this.state.order.CUST_REF}</strong></td>
          </tr>
         </table> 
    </div>
  );
}

}

export default PrintPanel;



/*
export default function PrintPanel(props) {    
  const [order, setOrder] = useState({});

  useEffect(() => {
    httpRequest.getOrder(props.account, props.id).then(response => {
      var _order = response.data;
      if(_order.dtDelivered)
          _order.dtDelivered_display = _order.dtDelivered.substring(0,19).replace("T"," ");
      if(_order.dtCallIn)  
          _order.dtCallIn_display = _order.dtCallIn.substring(0,19).replace("T"," "); 
      if(_order.dtDCT)
          _order.dtDCT_display = _order.dtDCT.substring(0,19).replace("T"," ");  
      setOrder(_order); 
    });        
  }, []);

   const renderFromAddress =() =>{
      let suite = order.sSuite? `${order.sSuite}, `:"";      
      return suite+ order.sAddress+" "+ order.sQuad
              +", "+ order.sCity+", "+ order.sProv+", "+ order.sPostal
              +", "+ order.sCountry;
  }

  const renderToAddress = () =>{
      let suite = order.cSuite? `${order.cSuite}, `:"";    
      return suite+ order.cAddress+" "+ order.cQuad
          +", "+ order.cCity+", "+ order.cProv+", "+ order.cPostal
          +", "+ order.cCountry;
  }


    return (
      <div>
         <img src={logo} alt="Direct-IT Group Courier" style={{ width: "20%", marginTop: 20,marginLeft: 15}}/>
         <table style={{marginLeft: 25}}>
              <tr>
                  <th className="td_head_title">
                      <strong className="titlefont"># {props.id}</strong>
                  </th>
                  <td>
                    <Barcode value={props.id}/>
                  </td>
              </tr>
         </table>  
         <table className="printTable">             
          <tr>
             <th className="td_title"> <strong className="printfont">Your Name & Phone</strong></th>
             <td className="black td_content"> <strong className="printfont">{order.CALLER}</strong></td>
          </tr>
          <tr>
             <th className="td_title"><strong className="printfont">Due by</strong></th>
             <td className="black td_content"> <strong className="printfont">{order.dtDCT_display + " " + order.timezone}</strong></td>
          </tr>
          <tr>   
             <th className="td_title"><strong className="printfont">Service Type & Minutes</strong></th>
             <td className="black td_content"> <strong className="printfont"><span>{order.SER_AREA}-{order.SER_TIME}</span></strong></td>
          </tr>
          <tr>
             <th className="td_title"><strong className="printfont">Call In</strong></th>             
             <td className="black td_content"> <strong className="printfont">{order.dtCallIn_display + " " + order.timezone}</strong></td>
          </tr>
          <tr>
            <th className="td_title"><strong className="printfont">From</strong></th>
            <td className="black td_content"> <strong className="printfont">{order.sCompanyName}</strong></td>
          </tr>
          <tr>   
            <th className="td_title"> <strong className="printfont">From Address</strong></th>
            <td className="black td_content"> <strong className="printfont"><span>{renderFromAddress()}</span></strong></td>
          </tr>
          <tr>
            <th className="td_title"> <strong className="printfont">To</strong></th>
            <td className="black td_content"><strong className="printfont">{order.cCompanyName}</strong></td>
          </tr>
          <tr>   
            <th className="td_title"> <strong claclassNamess="printfont">To Address</strong></th>
            <td className="black td_content"><strong className="printfont"><span>{renderToAddress()}</span></strong></td>
          </tr>
          <tr>
            <th className="td_title"> <strong className="printfont">Pieces</strong></th>
            <td className="black td_content"><strong className="printfont">{order.PIECES} pieces </strong></td>
          </tr>
          <tr>   
            <th className="td_title"><strong className="printfont">Weight</strong></th>
            <td className="black td_content"><strong className="printfont">{order.WEIGHT} lbs</strong></td>
          </tr>
          <tr>   
            <th className="td_title"><strong className="printfont">Reference</strong></th>
            <td className="black td_content"><strong className="printfont">{order.CUST_REF}</strong></td>
          </tr>
         </table> 
    </div>
  );
}
*/


