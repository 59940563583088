import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import large from '../../images/package-icons/Delivery-Type_Large_V2.jpg';
import styles from './styles';
import ImageHoverZoom from './ImageHoverZoom';


function PackageOptions(props) {


  function renderButtonImage() {
    if(props.image){
      var binary = '';
      var bytes = new Uint8Array(props.image.data);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
      }
      const base64String = btoa(binary);
      const imageSrc= "data:image/png;base64,"+ base64String;
      return imageSrc;
    }
    else{
      return large;
    }
  }

  return (
    <div style={styles.optionBox}>
        {/* <img src={renderButtonImage()} alt="Package Options"/>     */}
        <ImageHoverZoom src={renderButtonImage()} alt="Package Options"/>
        <Typography variant='subtitle1' style={styles.optionCaption}>{props.packageType} </Typography>       
    </div>
  );
}

export default PackageOptions;
