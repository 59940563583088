import axios from "axios";

//const base_url = "https://webxapi.azurewebsites.net/";
//const base_url = "http://localhost:6655/";
const base_url = window.location.origin +'/';
console.log("base_url-" + base_url);

//const FunctionKey  = "DgJK0wZz5iH62Z1hqRS97nJbou6K1NrhDiDMDpUaawqEg8xaEaK7Cg=="

class HttpRequest {

  getAPIVersion = () => {
    return axios.get(`${base_url}version`);
  };

  getTopAddress = (accountNumber, search) => {
    return axios.post(base_url + "topAddress/" + accountNumber, search);
  };

  getTopAddressByType = (accountNumber, search, type) => {
    return axios.post(
      base_url + "topAddress/" + accountNumber + "/" + type,
      search
    );
  };

  getExpandedAddress = (accountNumber, search, skip) => {
    return axios.post(
      base_url + "expandAddress/" + accountNumber + "/" + skip,
      search
    );
  };

  addAddress = (accountNumber, address) => {
    return axios.post(
      base_url + "addAddress/" + accountNumber + "/" + address.addressID,
      {}
    );
  };

  postAddress = (accountNumber, address) => {
    var content = {
      address: address,
      accountNumber: accountNumber,
    };
    return axios.post(base_url + "address", content);
  };

  addContact = (accountNumber, address) => {
    var content = {
      accountNumber: accountNumber,
      address: address,
    };
    return axios.post(base_url + "contact", content);
  };

  removeAddress = (accountNumber, address) => {
    return axios.delete(
      base_url + "removeAddress/" + accountNumber + "/" + address.addressID
    );
  };

  updateAddressContact = (aaccountNumber, addressID, contactID) => {
    var content = {
      addressID: addressID,
      contactID: contactID,
    };
    return axios.post(base_url + "addressContact/" + aaccountNumber, content);
  };

  getRateChoice = (accountNumber, postContent) => {
    return axios.post(base_url + "rateChoices/" + accountNumber, postContent);
  };

  submitOrder = (order) => {
    return axios.post(base_url + "order", order);
  };

  /** get Label String from DB
   * @param accountNumber, jobID
   */
  getLabelString = (id) => {
    return axios.get(base_url + "labelString/" + id);
  };

  getZPLLabel = (accountNumber, id, currentPage, totalPages) => {
    return axios.get(
      base_url +
        "ZPLlabel/" +
        accountNumber +
        "/" +
        id +
        "/" +
        currentPage +
        "/" +
        totalPages
    );
  };

  getOrderTrack = (orderID) => {
    return axios.get(base_url + "track/" + orderID);
  };

  /**
   * get accounts in group
   * @param groupID
   **/
  getGroupAccounts = (groupID) => {
    return axios.get(base_url + "groupAccounts/" + groupID);
  };

  getAddressByID = (accountNumber, addressID) => {
    return axios.get(base_url + "address/" + accountNumber + "/" + addressID);
  };

  /**
   * get Reference Fields
   * @param accountNumber
   **/
  getReferenceFields = (accountNumber) => {
    return axios.get(base_url + "referenceFields/" + accountNumber);
  };

  getReferenceDropDown = (refID) => {
    return axios.get(base_url + "referenceDropDown/" + refID);
  };

  /**
   * post_referenceFields,
   * @param jobId
   * @param reference
   */
  postReferenceFields = (jobID, reference) => {
    var content = {
      jobID: jobID,
      reference: reference,
    };
    return axios.post(base_url + "referenceFields", content);
  };

  getUserEmails = (accountNumber) => {
    return axios.get(base_url + "userEmails/" + accountNumber);
  };

  /**
   * get account service notes
   * @param accountNumber
   **/
  getAccountServiceNotes = (accountNumber) => {
    return axios.get(base_url + "accountServiceNotes/" + accountNumber);
  };

  orderSearch = (accountNumber, searchParams) => {
    var content = {
      accountNumber: accountNumber,
      searchParams: searchParams,
    };
    return axios.post(base_url + "searchOrders", content);
  };

  /**
   * get orders
   * @param accountNumber
   **/
  getOrders = (accountNumber) => {
    return axios.get(base_url + "orders/" + accountNumber);
  };

  /**
   * get order
   * @param accountNumber
   * @param orderID
   **/
  getOrder = (accountNumber, orderID) => {
    return axios.get(base_url + "order/" + accountNumber + "/" + orderID);
  };

  /**
   * get Job GPS
   * @param orderID
   **/
  getJobGPS = (orderID) => {
    return axios.get(base_url + "jobGPS/" + orderID);
  };

  getPackageChoice = (accountNumber, postContent) => {
    return axios.post(
      base_url + "packageChoices/" + accountNumber,
      postContent
    );
  };

  /**
   * get signature photo
   * @returns {Promise}
   * in order status page
   */
  getSignature = (accountNumber, jobID) => {
    return axios.get(
      base_url + "orderSignature/" + accountNumber + "/" + jobID
    );
  };

  /**
   * get list of pictures of the order
   * @returns {Promise}
   * in order status page
   */
  getPhotoURLs = (orderID) => {
    return axios.get(base_url + "photoURL/" + orderID);
  };

  /* update weight  in order status (order detail page)  */
  patchUpdateWeight = (accountNumber, jobID, newWeight) => {
    var content = {
      accountNumber: accountNumber,
      jobID: jobID,
      newWeight: newWeight,
    };
    return axios.patch(
      base_url + "orderUpdateWeight/" + accountNumber + "/" + jobID,
      content
    );
  };

  /* update phone number  in order status (order detail page)  */
  callMe = (accountNumber, jobID, phone) => {
    var content = {
      accountNumber: accountNumber,
      jobID: jobID,
      phone: phone,
    };
    return axios.post(
      base_url + "orderCall/" + accountNumber + "/" + jobID,
      content
    );
  };

  /* login page  - rquest for new sso-token*/
  generateLoginToken = (body) => {
    return axios.patch(base_url + "generateLoginToken", body);
  };

  /**
   * sso_token is from url  ex: https://ship.directx.ca/#?sso_token=6b36437b-522e-499a-9802-1fc74b4f2f30
   * get account and user detail by sso_token if it is available
   * @returns {Promise}
   */
  postLoginToken = (token) => {
    var content = {
      sso_token: token,
    };
    return axios.post(base_url + "loginToken?", content);
  };

  /*old version called Login */
  /*called when selected different account form account list on Create Order page */
  getAccountDetail = (accountNumber) => {
    var data = {
      accountNumber: accountNumber,
    };
    return axios.post(base_url + "login", data);
  };

  getInvoices = (accountNumber) => {
    return axios.get(base_url + "invoices/" + accountNumber);
  };

  /**
   * check Invoice Password
   * @returns {*}
   * @constructor
   */
  checkInvoicePassword = (accountNumber, password) => {
    var data = {
      aaccountNumber: accountNumber,
      password: password,
    };
    return axios.post(base_url + "invoicePassword", data);
  };

  /**
   * post address with addressID and google return json string
   * @param addressID
   * @param addrSearchResult
   */

  postAddressEXT = (addressID, addrSearchResult) => {
    var content = {
      addrSearchResult: addrSearchResult,
      addressID: addressID,
    };
    return axios.post(base_url + "addressEXT", content);
  };

  /* add new caller ( name and email) 
    * caller = {
    *    username: ggggg,
    *    emailAddress:  qwe@123.com
    }
    */
  postUserEmails = (accountNumber, caller) => {
    var content = {
      caller: caller,
      accountNumber: accountNumber,
    };
    return axios.post(base_url + "userEmails", content);
  };

  /* remove caller (name and email)
   *  caller=
   *    {
   *    username: ggggg
   *   }
   */
  /**
   *
   * @param {*} accountNumber
   * @param {*} callerName
   * @returns
   */
  removeCaller = (accountNumber, callerName) => {
    var content = {
      username: callerName,
    };
    return axios.post(base_url + "caller/" + accountNumber, content);
  };

  /**
   * send webMessage
   * @param messageID
   * @returns {promise}
   */
  sendWebMessage = (accountNumber, message) => {
    var content = {
      msg: message,
      accountNumber: accountNumber,
    };
    return axios.post(base_url + "webMessage", content);
  };

  /**
   *
   * @param {Int} accountNumber
   * @param {String []} invoice_List
   * @param {Float} payment_Amount
   * @param {String} conf_Numer
   * @returns
   */
  payInvoices = (accountNumber, invoiceList, paymentAmount, confNumber) => {
    var content = {
      //     IMEI: null,
      invoiceList: invoiceList,
      paymentAmount: paymentAmount,
      confNumber: confNumber,
      //     json: null
    };
    return axios.post(base_url + "payInvoices/" + accountNumber, content);
  };

  /**
   * doUploadImage
   * @param params
   **/
  doUploadImage = (base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return axios.post(base_url + "image", content);
  };

  /**
   * doUploadPDF
   * @param params
   */
  doUploadFile = (base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return axios.post(base_url + "file", content);
  };

  /**
   * add attachment
   * @param params
   */
  doAttachment = (jobID, URL, fileType, comment) => {
    var content = {
      URL: URL,
      fileType: fileType,
      comment: comment,
    };
    return axios.post(base_url + "attachment/" + jobID, content);
  };

  /**
   * obtaines transaction token for converge
   * @param {Float} amount
   * @param {String} invoiceNumber
   * @param {Int} account
   * @returns
   */
  getTransactionToken = (account, amount, invoiceNumber) => {
    console.log("sending request to create checkout session " + amount);
    const saleData = {
      ssl_amount: amount,
      ssl_invoice_number: invoiceNumber,
      ssl_description: account,
    };
    return axios.post(base_url + "/transactionToken", saleData);
  };
}

const httpRequest = new HttpRequest();

export default httpRequest;
