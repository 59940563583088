import React from 'react';
//import { useState, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { Button, Box} from '@material-ui/core';
import KeyIcon from '@material-ui/icons/VpnKey';
import TextInput from '../newOrder/components/inputFields/TextInput';
import './Login.css';
import httpRequest from '../HttpService';
import 'animate.css/animate.css';
import {Alert, AlertTitle} from '@material-ui/lab'
import Copyright from '../Copyright';


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           accountNumber:"",
           email:"",
           user_name:"",
           loginURL:"",
           successinfo:"none"
        }
        this.handleGenerateKeyRequired = this.handleGenerateKeyRequired.bind(this);
    }

    handleGenerateKeyRequired = (event) => {
           console.log("generate key-" + JSON.stringify(this.state));
           httpRequest.generateLoginToken(this.state).then((body) =>{
                console.log("generate key return-" + JSON.stringify(body));
                this.setState({successinfo:"block"});
           });
    }

    handleChange = (event) => {
        console.log(event.target.name, event.target.value);
        const { name, value } = event.target;
        this.setState({[name]: value});
    };


    render() {
        return (
            <Container  class="Login">
            <div class={"login-wrapper"}>
            <div className={"login-wrapper-inner"}>
            <Grid 
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
                <Grid item md={12}>
                    <h2 class={"text-primary text-uppercase title-small"}> Client Login </h2>
                </Grid>
                <Grid item md={12} style={{width:"100%"}}>
                    <TextInput
                      class={"labeledField"}
                      label = "Client Account"
                      type="text"
                      inputType="text"
                      name="accountNumber"                      
                      labelSize = {5}    
                      value={this.state.accountNumber}     
                      onChange={this.handleChange}           
                    />
                </Grid>
                <Grid item md={12} style={{width:"100%"}}>
                    <TextInput
                      class={"labeledField"}
                      label = "Your Email"
                      type="text"
                      inputType="text"
                      name="email"
                      labelSize = {5} 
                      value={this.state.email}  
                      onChange={this.handleChange}                        
                    />
                </Grid>
                <Grid item md={12} style={{width:"100%"}}>
                    <TextInput
                      class={"labeledField"}
                      label = "Your Name"
                      type="text"
                      inputType="text"
                      name="user_name"
                      labelSize = {5} 
                      value={this.state.user_name} 
                      onChange={this.handleChange}                         
                    />
                </Grid> 
                <Grid  item md={12} style={{width:"260px", textAlign:"center"}}>
                        <Button
                           style={{width:"100%", display:'flex', justifyContent:'space-between', marginTop:'20px'}}
                           variant="contained"                           
                           color="primary" 
                           endIcon={<KeyIcon style={{verticalAlign: 'middle', transform: 'scale(1)'}}/>}    
                           disabled={(this.state.accountNumber === "") && (this.state.email==="")}                   
                           onClick={this.handleGenerateKeyRequired}
                         >
                         Generate Token
                         </Button>
                </Grid>  

                <Grid item md={12} style={{marginTop:"100px"}}>
                    <Box display={this.state.successinfo}>
                    <Alert severity="success">
                    <AlertTitle>Check Your Email </AlertTitle>
                    <strong>
                    Hi {this.state.email}, you are all set,click on the login link in the email.<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*email will arrive from directitgroup.ca.
                    </strong>
                    <div>
                       <h4>Did you also know?</h4>
                       <ul>
                       <li>You can use the link on any type of device, Photo, Tablet, PC.</li>
                       <li>We recommend saving the link(token) for future use. See our video below.</li>
                       <li>Any previous links generated will no longer work.</li>
                       <li>You can now close this page.</li>
                       </ul>
                   </div>
                   </Alert>
                   </Box>
                </Grid>   
            </Grid>
            </div>
            </div>
            {/* Footer */}
            <footer  style={{float:"center"}}>
                <Copyright/>
            </footer>
            {/* End footer */}
            </Container>
        );
    }
}

export default Login;
