import "./App.css";

import React, { useState, useEffect } from "react";
import NewOrder from "./newOrder";
import OrderStatus from "./orderStatus/orderStatus";
import OrderDetail from "./orderStatus/orderDetail";
//import Invoice from "./invoices";
import Invoice from "./invoices/invoices";
import Login from "./login";
import Tracking from "./tracking";
import TrackingOrderDetail from "./orderStatus/trackingOrderDetail";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import HttpRequest from "./HttpService";
import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import NavigationBar from "./NavigationBar";
import WDWebview from "./WDWebview";
import ExtendWeb from "./ExtendWeb";
import Preferences from "./preferences";
import OrderHistory from "./history";

import Success from "./checkout/Success";
import Canceled from "./checkout/Canceled";
import packageJson from "../package.json";
import axios from "axios";

function App() {
  require("dotenv").config();

  const [loginUser, setLoginUser] = useState({});
  const [userAddress, setUserAddress] = useState({});
  const [accountList, setAccountList] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //const [checkedToken, setCheckedToken] = useState(true);
/*
  useEffect(() => {
    const loadScript = (callback) => {        
          const tidioScript = document.createElement("script");
          tidioScript.src = "//code.tidio.co/zj4irmzxslnsmyu84t0zthq5r1m9jgxl.js";
          window.document.body.appendChild(tidioScript);  
    }
    loadScript(() => {
        //console.log(" script loaded")       
    });
  }, []);
  */
  useEffect(() => {
    let token = "";
    console.log("App wondow token is", window.token);
    console.log("App url is", window.location.href);
    token = window.location.href.split("sso_token=")[1];
    console.log("App token", token);

    HttpRequest.getAPIVersion().then(async(body)=> {      
      //console.log("getAPIVersion:" + body.data);
      if(body.data.message!== undefined){
         localStorage.setItem("APIVersion", body.data.message.substring(body.data.message.indexOf(":") + 1));       
      }
    });
        
    if (token === undefined) {
      token = localStorage.getItem("sso_token");
      console.log("token", token);
      if (token === null) {
        return;
      }
    } else {
      localStorage.setItem("current_accountNumber", "");
      localStorage.setItem("current_addressID", "");
      localStorage.setItem("current_HasInvoicePassword", "");
      localStorage.setItem("current_CompanyID", "");

      localStorage.setItem("previousOrder", "");
      localStorage.setItem("previousOrderRef", "");

      if (window.location.href.includes("CreateOrder"))
        localStorage.setItem("IsEmbedded", "yes");
      else localStorage.setItem("IsEmbedded", "no");
    }
    window.token = token;

    HttpRequest.postLoginToken(token)
      .then((body) => {
        console.log("Post Login Token", body.data);
        if (body.data.hasOwnProperty("error")) {
          console.log("wrong token", body.error);
          window.location.href = "/";
        } else {
          localStorage.setItem("sso_token", token);
          //setCheckedToken(true);
          setLoginUser(body.data.user);
          console.log(
            "Post Login Token -account",
            body.data.user.accountNumber
          );
          localStorage.setItem("accountNumber", body.data.user.accountNumber);
          localStorage.setItem("groupID", body.data.user.groupID);
          localStorage.setItem("addressID", body.data.user.address.addressID);
          localStorage.setItem(
            "HasInvoicePassword",
            body.data.user.HasInvoicePassword
          );
          localStorage.setItem("CompanyID", body.data.user.CompanyID);

          setIsAuthenticated(true);
          const accounts = [];
          let groupID = body.data.user.groupID;
          console.log("localStorag groupID --" + groupID);
          if (groupID !== null) {
            HttpRequest.getGroupAccounts(groupID).then(async function (body) {
              console.log("getGroupAccounts:" + JSON.stringify(body.data));
              await body.data.forEach((person) => {
                accounts.push({
                  value: person.account,
                  label: person.accountName,
                });
              });
              setAccountList(accounts);
            });
          }
          HttpRequest.getAddressByID(body.data.user.accountNumber, body.data.user.address.addressID).then(async (body) => {
            console.log("getAddressByID:" + JSON.stringify(body.data[0]));
            setUserAddress(body.data[0]);
            localStorage.setItem("userAddress", JSON.stringify(body.data[0]));
          });
        }
      })
      .catch((body) => {
        console.log("error", body);
        // setCheckedToken(true);
      });

      axios.get('https://api.ipify.org/').then((res)=> {     
        localStorage.setItem("ipaddress",res.data);
      });

  }, []);

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Router>
          <NavigationBar isLoggedIn={isAuthenticated} />
          <Routes>
            <Route path="/"  
              element={!isAuthenticated || !userAddress.addressID ? <Login /> : <NewOrder accountList={accountList} />}>
            </Route>            
            <Route path="/neworder" element={<NewOrder accountList={accountList}/>}/>           
            <Route path="/orderstatus" element={<OrderStatus />} />  
            <Route path="/orderDetail/:orderID/:account"  element={<OrderDetail variant="orderStatus" />} />             
            <Route path="/trackOrderDetail/:orderID/:account" element = {<OrderDetail variant="orderTrack" />} />          
            <Route path="/invoices" element={<Invoice />} />           
            <Route path="/faq" element = {<WDWebview url="https://www.directitgroup.ca/faq" />} />
            <Route path="/web/:type" element ={<ExtendWeb />} />
            <Route path="/history" element={<OrderHistory />} />              
            <Route path="/preferences" element={<Preferences />} />            
            <Route path="/tracking" element={<Tracking />} />          
            <Route path="/trackNo/:trackingNumber" element={<TrackingOrderDetail trakingType="trackingNumber"/>} />
            <Route exact path="/TrackAccount/:trackingAccount/:trackingReference"
                elelment={<TrackingOrderDetail trakingType="reference"/>} />
            <Route path="/success" element={<Success />} />            
            <Route path="/canceled" element={<Canceled />} /> 
          </Routes>
        </Router>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default App;

//http://localhost:3000/#?sso_token=7603cd07-84f9-4903-b009-75bc5457bab5
