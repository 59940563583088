import React , { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import SelectInput_ORG from './SelectInput_org';
import styles from './styles';
import CustomIconButton from './CustomIconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

/** 
    Custom Input
    takes in props
    @inputType - either text or select
    @multiline - if text input is multiline
    @optionList - options if select inputType
    @value - value displayed by the input
    @onChange - onChange handler
    @label - label (title) of the input
    @labelSize - 1-12 how much of the width is the label vs the input
    @color - default is primary <-blue could choose secondary (ie red)
    @icon - icon if there is an icon
    @onIconClick - when icon is clicked
**/


function CustomInput(props) {


    const handlerNumberChange = (event) => {
        if(event.target.value.toString().length>props.inputLength){
          let temp = event.target.value.toString().substring(0, props.inputLength);
          event.target.value = temp;          
        }  
    }
    

    function renderInput() {
        if (props.inputType === "select") {
            return (
                <SelectInput_ORG
                    optionList = {props.optionList}
                    value = {props.value}
                    onChange = {props.onChange}
                    label = {props.label}
                    labelSize={props.labelSize}
                    color={props.color}
                    {...props}/>
            );
        }
        if (props.inputType === "text" && props.multiline) {
            return (
                <TextInput
                    multiline
                    minRows={4}
                    id = {props.name}
                    type={props.type}
                    label={props.label}
                    labelSize={props.labelSize}
                    inputProps={{ maxLength: props.inputLength }}                    
                    variant="outlined"
                    size="large"
                    color={props.color}
                    onChange = {props.onChange}
                    {...props}/>
            );
        }

        if (props.inputType === "text") {
            return (
                <TextInput
                    type={props.type}
                    label={props.label}
                    id={props.name}
                    labelSize={props.labelSize}
                    inputProps={{ maxLength: props.inputLength }}
                    defaultValue={props.defaultValue}
                    variant="outlined"
                    size="small"
                    color={props.color}
                    onChange = {props.onChange}
                    {...props}/>
            );
        }

        if (props.inputType === "number") {
            return (
                <TextInput
                    type={props.type}
                    label={props.label}
                    id={props.name}
                    labelSize={props.labelSize}
                    defaultValue={props.defaultValue}
                    variant="outlined"
                    size="small"
                    color={props.color}
                    //onChange = {props.onChange}
                    onChange = {handlerNumberChange}
                    {...props}/>  
            );
        }
    }

  

    return (
        <div>          
            <Grid
                container
                direction="row"
                //justifyContent='flex-end'
                justifyContent='stretch'
                style={{margin:'0', width:'100%' }}
                alignItems="stretch"
                spacing={0}
                sm={12}
                >
                <Grid item sm={10}>
                    {renderInput()}
                </Grid>
                <Grid item sm={2}>
                    {props.icon && <CustomIconButton
                        icon={props.icon}
                        tooltip={props.iconTooltip}
                        onClick={props.onIconClick}
                        color={props.color}/>}
                </Grid>
            </Grid>
        </div>

    );
}

export default CustomInput;