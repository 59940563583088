import React, { useState, useEffect } from "react";
import HttpRequest from "../../../HttpService";
import { makeStyles } from "@material-ui/core/styles";

/*
export class MapContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state ={
            accountAddress : {}
        }
    }

    styles = {
        container: {
            display: 'flex',
            position: 'relative',
            minHeight: '500px',
            height: '75%',
            width: '100%',
            top:this.props.top,
            //margin: 0,
            //marginRight: 10,
            //marginLeft: 10,
            border: 0,
        },
    }

    shouldComponentUpdate() {
         return this.props.IsUpdateMap; 
    }

    
    componentDidMount = () =>  {
        //if(this.props.type === "directions"){
            var userAddressID = localStorage.getItem('addressID'); 
            HttpRequest.getAddressByID(this.props.accountNumber, userAddressID).then(async (body) =>{   
               // console.log("MapContainer getAddressByID:" + JSON.stringify(body.data[0]));                 
                localStorage.setItem('userAddress', JSON.stringify(body.data[0]));    
                this.setState({accountAddress: body.data[0]});              
            });
        //}
    }
    

    render() { 
       
        let consignCoord ={};
        let consignAddress = "";
        let shipperCoord = {};
        let shipperAddress = "";   
        var userAddress = localStorage.getItem('userAddress');
        if(userAddress && userAddress !== ""){
           var defaultAddress = JSON.parse(userAddress);
           ////var defaultAddress = this.state.accountAddress;
           //console.log("mapContainer retrieved defaultAddress is " + JSON.stringify(defaultAddress)); 
           consignCoord = {lat: defaultAddress.lat, long: defaultAddress.long};
           consignAddress = defaultAddress.companyName;
           shipperCoord = {lat: defaultAddress.lat, long: defaultAddress.long};
           shipperAddress = defaultAddress.companyName;       
        }    
        else{  
           consignCoord = {lat: 51.052224, long: -114.068237};
           consignAddress = "DirectIT Group";
           shipperCoord = {lat: 51.052224, long: -114.068237};
           shipperAddress = "DirectIT Group";
        }

        //console.log('map container -shipperAddress is ' + this.props.shipperAddress);
        //console.log('map container -accountAddress is ' + JSON.stringify(this.state.accountAddress));

        if(this.props.consignAddress && !this.props.consignAddress.startsWith('undefined')) {
            consignCoord = this.props.consignCoord;
            consignAddress = this.props.consignAddress;           
        }
        if(this.props.shipperAddress && !this.props.shipperAddress.startsWith('undefined')) {
            shipperCoord = this.props.shipperCoord;
            shipperAddress = this.props.shipperAddress;         
        }

        //console.log('map container -shipperCoord is ' + JSON.stringify(shipperCoord));  

        let url = ( this.props.type === "place" ?
            //"https://www.google.com/maps/embed/v1/streetview?key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&heading=210&pitch=10&fov=35&location=" + shipperCoord.lat + "," + shipperCoord.long
            "https://www.google.com/maps/embed/v1/streetview?key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&heading=210&pitch=10&fov=35&pitch=85&location=" + consignCoord.lat + "," + consignCoord.long
            // "https://www.google.com/maps/embed/v1/place?key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&q=" + this.props.consignAddress
            // :consignCoord.lat === shipperCoord.lat?
            //  "https://www.google.com/maps/embed/v1/view?key=" + process.env.REACT_APP_GOOGLE_API_KEY + " &zoom=18&maptype=roadmap&center=" + consignCoord.lat + "," + consignCoord.long
             :
            //"https://www.google.com/maps/embed/v1/directions?key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&origin=" + shipperAddress + "&destination=" + consignAddress);
            "https://www.google.com/maps/embed/v1/directions?&key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&origin=" + shipperCoord.lat+','  + shipperCoord.long+ "&destination=" + consignCoord.lat + "," + consignCoord.long);    

        //console.log("mapContainer url is " + url);
        return (
             <iframe
                style={this.styles.container}
                loading="lazy"
                allowfullscreen
                src={url}
                title="Google Map"
            />        
        );         
    }
}
 
export default MapContainer;
*/

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    position: "relative",
    minHeight: "500px",
    height: "75%",
    width: "100%",
    top: 30,
    //margin: 0,
    //marginRight: 10,
    //marginLeft: 10,
    border: 0,
  },
}));

const MapContainer = (props) => {
  const [accountAddress, setAccountAddress] = useState(null);
  const [url, setUrl] = useState("");

  const classes = useStyles();

  useEffect(() => {
    var userAddressID = localStorage.getItem("addressID");
    HttpRequest.getAddressByID(props.accountNumber, userAddressID).then(
      async (body) => {
        // console.log("MapContainer getAddressByID:" + JSON.stringify(body.data[0]));
        localStorage.setItem("userAddress", JSON.stringify(body.data[0]));
        setAccountAddress(body.data[0]);
        //let url =  "https://www.google.com/maps/embed/v1/streetview?key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&heading=210&pitch=10&fov=35&pitch=85&location=" + body.data[0].lat + "," + body.data[0].long;
        //let url = "https://www.google.com/maps/embed/v1/directions?&key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&origin=" +  body.data[0].lat+','  +  body.data[0].long+ "&destination=" +  body.data[0].lat + "," +  body.data[0].long;
        //setUrl(url);
      }
    );
  }, []);

  useEffect(() => {
    //console.log('map container -consignee coordinator is ' + JSON.stringify(props.consignCoord));

    let consignCoord = {};
    let consignAddress = "";
    let shipperCoord = {};
    let shipperAddress = "";
    var userAddress = localStorage.getItem("userAddress");
    if (userAddress && userAddress !== "") {
      var defaultAddress = JSON.parse(userAddress);
      ////var defaultAddress = this.state.accountAddress;
      //console.log("mapContainer retrieved defaultAddress is " + JSON.stringify(defaultAddress));
      consignCoord = { lat: defaultAddress.lat, long: defaultAddress.long };
      consignAddress = defaultAddress.companyName;
      shipperCoord = { lat: defaultAddress.lat, long: defaultAddress.long };
      shipperAddress = defaultAddress.companyName;
    } else {
      consignCoord = { lat: 51.052224, long: -114.068237 };
      consignAddress = "DirectIT Group";
      shipperCoord = { lat: 51.052224, long: -114.068237 };
      shipperAddress = "DirectIT Group";
    }

    //console.log('map container -shipperAddress is ' + this.props.shipperAddress);
    //console.log('map container -accountAddress is ' + JSON.stringify(this.state.accountAddress));

    if (props.consignAddress && !props.consignAddress.startsWith("undefined")) {
      consignCoord = props.consignCoord;
      consignAddress = props.consignAddress;
    }
    if (props.shipperAddress && !props.shipperAddress.startsWith("undefined")) {
      shipperCoord = props.shipperCoord;
      shipperAddress = props.shipperAddress;
    }

    //console.log('map container -shipperCoord is ' + JSON.stringify(shipperCoord));
    try {
      let url =
        props.type === "place"
          ? "https://www.google.com/maps/embed/v1/streetview?key=" +
            process.env.REACT_APP_GOOGLE_API_KEY +
            "&heading=210&pitch=10&fov=35&pitch=85&location=" +
            consignCoord.lat +
            "," +
            consignCoord.long
          : "https://www.google.com/maps/embed/v1/directions?&key=" +
            process.env.REACT_APP_GOOGLE_API_KEY +
            "&origin=" +
            shipperCoord.lat +
            "," +
            shipperCoord.long +
            "&destination=" +
            consignCoord.lat +
            "," +
            consignCoord.long;

      //console.log('map container -url is ' + url);
      setUrl(url);
    } catch (e) {
      console.log("mapConatainer", e);
    }

    //console.log("mapContainer url is " + url);
  }, [props.consignCoord, props.shipperCoord]);

  return (
    <iframe
      className={classes.container}
      loading="lazy"
      allowFullscreen
      src={url}
      title="Google Map"
    />
  );
};
export default MapContainer;
