import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';



const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing.unit * 3,
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);
  

function SelectInput(props) {

    let optionList = props.optionList && props.optionList.map((item, i) => {
        return (
            // <MenuItem value={item}>{item}</MenuItem>
            <option value={item.value}>{item.label}</option>
        )
    }, this);

    return (
        <Grid
            container
            direction="row"
            justifyContent={props.justifyContent}
            alignItems="stretch"
            spacing={0}
            style={styles.root}
            >
            <Grid item sm={props.labelSize} style={styles.labelWrapper}>
                <Typography variant = 'subtitle1' style={styles.label}>{props.label}</Typography>
            </Grid>
            <Grid item sm={12 - props.labelSize}>
            {/* <TextField variant="outlined" className="formControlTextfield" autoWidth={true} size="small"/> */}
            <FormControl size="small" fullWidth>
                <Select
                    //variant="outlined"  
                    input={<BootstrapInput name="" id="customized-select" />}
                    style={styles.formControlSelect}
                    defaultValue="Please Select ... "
                    native
                    {...props}
                >
                    {/* <MenuItem value="select">Please Select ... </MenuItem> */}
                    <option value="select">Please Select ... </option>
                    {optionList}
                </Select>
            </FormControl>
            </Grid>
        </Grid>
    );
}

export default SelectInput;