import React, { useState, useEffect } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import WarningIcon from '@material-ui/icons/Warning';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import Tooltip from '@material-ui/core/Tooltip';
import styles from './styles';
import CustomIconButton from '../inputFields/CustomIconButton';
import TextInput from '../inputFields/TextInput';
import httpRequest from '../../../HttpService';


function QuickSelectOption(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [borderColor, setBorderColor] = useState('2px solid #c4c4c4');
    const [pickUpInfo, setPickUpInfo] = useState("");
    const [deliveryInfo, setDeliveryInfo] = useState("");
    const [specs, setSpecs] = useState("");
    const [nameValue, setNameValue] = useState(props.deliveryLocation.contactName);
    const [phoneValue, setPhoneValue] = useState(props.deliveryLocation.contactPhone);
    const open = Boolean(anchorEl);


    //useEffect for border color
    useEffect(() => {
        if(props.currentlySelected !== props.service){
            setBorderColor('1px solid #c4c4c4');
        }
    },[props.currentlySelected])

    //useEffect for rate details
    useEffect(() => {
        var descr = props.service.descr;
        const puPos = descr.indexOf('PU');
        const delPos = descr.indexOf('Del');
        const bar = descr.indexOf('|');

        if(puPos !== -1 && delPos !== -1){
        setPickUpInfo(descr.slice(puPos+4, delPos-4));
        setDeliveryInfo(descr.slice(delPos+4, bar-1));
        }
        else if(delPos === -1){
        setPickUpInfo(descr.slice(puPos+4, bar-1));
        setDeliveryInfo("N/A");
        }
        else if(puPos === -1){
        setPickUpInfo("N/A");
        setDeliveryInfo(descr.slice(delPos+4, bar-1));
        }

        setSpecs(descr.slice(bar+2));
    },[props.service]);

    function renderIcon() {
        const base64String = btoa(String.fromCharCode(...new Uint8Array(props.service.buttonImage.data)));
        const imageSrc= "data:image/svg+xml;base64,"+ base64String;
        return <img src={imageSrc} alt="Rate Icon"/>
    }

    const handleClick = (event) => {
        console.log("handleClick--" + event.currentTarget);
        if((!props.hasContact && (props.service.ser_area==='A'|| props.service.ser_area==='H'|| props.service.ser_area==='Z' || props.service.ser_area==='HP')))
           setAnchorEl(event.currentTarget);
        else
           handleSave(event);
              
    };

    const handleSave = (event) => {
        setAnchorEl(null);
        event.preventDefault();
        props.onChange(event, props.service);
        setBorderColor('2px solid #3379b7');

        if((!props.hasContact && (props.service.ser_area==='A'|| props.service.ser_area==='H'|| props.service.ser_area==='Z' || props.service.ser_area==='HP'))){
             //Update Address Data
             props.deliveryLocation.contactName = nameValue;
             props.deliveryLocation.contactPhone = phoneValue;
             console.log(props.deliveryLocation);

             httpRequest.addContact(props.account, props.deliveryLocation).then(response => {
                 console.log('contact added', response.data[0].contactID);
                 props.deliveryLocation.contactID = response.data[0].contactID;
                 httpRequest.updateAddressContact(props.account, props.deliveryLocation.addressID, response.data[0].contactID).then(res2 => {
                     console.log('update address contact', res2);
                 });
           });
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleName = (event) => {
        setNameValue(event.target.value);
    }
    const handlePhone = (event) => {
        const formattedPhone = addDashes(event.target.value);
        setPhoneValue(formattedPhone);
    }

    function addDashes(f){
        const f_val = f.replace(/\D[^\.]/g, "");
        if(f_val.length <= 3) {
            return f_val;
        }
        if (f_val.length <= 6) {
            return f_val.slice(0,3)+"-"+f_val.slice(3,6);
        }
        return f_val.slice(0,3)+"-"+f_val.slice(3,6)+"-"+f_val.slice(6);
    }

    let dateFormatted = "";
    if (props.deliveryLocation.LastDate) {
        const date = new Date(props.deliveryLocation.LastDate.split("T")[0]);
        const dateString = date.toDateString();
        dateFormatted = dateString.split(" ")[1] + " " + dateString.split(" ")[2] + ", " + dateString.split(" ")[3];
    }

    return (
        <div>
        <div onClick={handleClick}>
            <Card style={{marginRight: '10px', width: '275px', border: borderColor}}>
                <CardContent style={{display: 'relative'}}>
                    <div style={styles.cardTitleRow}>
                        <div style={styles.cardTitleIcon}>
                            {renderIcon()}
                        </div>
                        <Typography variant="body1" style={styles.cardHeader}>
                            {props.service.buttonText1}
                        </Typography>
                    </div>
                    <Typography variant="body2" style={styles.description}>
                            {props.service.buttonText2}
                    </Typography>
                    {/* <Typography variant="body2" style={styles.description}>
                        PU: {pickUpInfo}
                    </Typography>
                    <Typography variant="body2" style={styles.description}>
                        DEL: {deliveryInfo}
                    </Typography>
                    <Typography variant="body2" style={styles.description}>
                        {specs !== "" ? specs : "\b"}
                    </Typography> */}
                    <Divider style={styles.divider}/>
                    <Typography variant="body2" style={styles.priceText}>
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(props.service.Rate)}
                    </Typography>
                </CardContent>
            </Card>
        </div>
        
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <Box style={{margin: '10px'}}>
                <Box style={styles.warningBannerBox}>
                    <WarningIcon color="error" fontSize="small"/>
                    <Typography color="error">Please provide contact name and phone:</Typography>
                </Box>
               
                <Typography variant='h6'>{props.deliveryLocation.companyName}</Typography>
                {(props.deliveryLocation.suite !== '') ? 
                    (<Typography>Suite {props.deliveryLocation.suite},  {props.deliveryLocation.address}, {props.deliveryLocation.quad}</Typography>)
                    : (<Typography>{props.deliveryLocation.address}, {props.deliveryLocation.quad}</Typography>)
                }

                <Box>
                    <Typography style={{display: 'inline'}}>{props.deliveryLocation.city}, {props.deliveryLocation.prov}, {props.deliveryLocation.country}, {props.deliveryLocation.postal}</Typography>
                    <Tooltip title={<div style={{fontSize: 15}}>{props.deliveryLocation.addressID}</div>} placement="top" arrow>
                    <LocationOnIcon style={styles.inlineIcon}/>
                    </Tooltip>
                </Box>

                <Box>
                    <Typography style={{fontWeight: 'bold', display: 'inline'}}>Last delivery completed: </Typography>
                    <Typography style={{display: 'inline'}}>{dateFormatted}</Typography>
                    <Tooltip title={<div style={{fontSize: 15}}>{`${props.deliveryLocation.lat}, ${props.deliveryLocation.long}`}</div>} placement="top" arrow>
                    <GpsFixedIcon style={styles.inlineIcon}/>
                    </Tooltip>
                </Box>

                <Box style={styles.root}>
                 <TextInput
                        type= "name"
                        inputType= "text"
                        label= "Name"
                        name = "name"
                        variant="outlined"
                        size="small"
                        color={props.hasContact ? "primary" : "secondary"}
                        onChange = {handleName}
                        labelSize = {3}
                        defaultValue={props.deliveryLocation.contactName}/>
                     <TextInput
                        type= "phone"
                        inputType= "text"
                        label= "Phone"
                        name = "phone"
                        variant="outlined"
                        size="small"
                        color={props.hasContact ? "primary" : "secondary"}
                        onChange = {handlePhone}
                        labelSize = {3}
                        defaultValue={props.deliveryLocation.contactPhone}/> 
                    <CustomIconButton icon="DoneIcon" color={props.hasContact ? "primary" : "secondary"} onClick={handleSave}/>
                </Box>
            </Box>
        </Popover>
        </div>
    );
}

export default QuickSelectOption;
