import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.js';


 

function SelectInput(props) {

    let optionList = props.optionList && props.optionList.map((item, i) => {
        return (
            // <MenuItem value={item}>{item}</MenuItem>
            <option value={item.value}>{item.label}</option>
        )
    }, this);

    return (
        <Grid
            container
            direction="row"
            justifyContent={props.justifyContent}
            alignItems="stretch"
            spacing={0}
            style={styles.root}
            >
            <Grid item sm={props.labelSize} style={styles.labelWrapper}>
                <Typography variant = 'subtitle1' style={styles.label}>{props.label}</Typography>
            </Grid>
            <Grid item sm={12 - props.labelSize}>
            {/* <TextField variant="outlined" className="formControlTextfield" autoWidth={true} size="small"/> */}
            <FormControl size="small" fullWidth>
                <InputLabel>
                    {props.label}
                </InputLabel>
                <Select
                    variant="outlined"  
                    style={styles.formControlSelect}
                    defaultValue="Please Select ... "
                    input={
                        <OutlinedInput
                          name= {props.label}
                        />
                    }
                    native
                    {...props}
                >
                    {/* <MenuItem value="select">Please Select ... </MenuItem> */}
                    <option value="select">Please Select ... </option>
                    {optionList}
                </Select>
            </FormControl>
            </Grid>
        </Grid>
    );
}

export default SelectInput;