import * as React from "react";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Container,Grid,Typography,IconButton,} from "@material-ui/core";
import Panel from "./whitePanel";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import httpRequest from "../../HttpService";
import {getStatusString,getLocationString,} from "../../newOrder/components/helperFunctions/index.js";
import logo from "../../directitgroupcourier.jpg";
import moment from "moment";
import "../orderDetail.css";


class OrderReceiptPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: localStorage.getItem("accountNumber"),
      activeStep: 0,
      showDeliveryDetails: true,     
      steps: [],
      order: {}          
    };
  }

  componentDidMount = () => {
    console.log("Order detail componentDidMount");  
    this.initOrderDetail();    
    setTimeout(() => {this.props.doPrintReceipt();}, 1000); 
  };


  initOrderDetail = () =>{
    this.setState({order:this.props.order})
    httpRequest.getOrderTrack(this.props.order.jobID).then((response) => {
        console.log("getOrderTrack--" + JSON.stringify(response.data));
        var tempTrackList = response.data.map((option,index) => {
          return{
            ...option,
            id: index
          };
      });
      this.setState({ steps: tempTrackList });
    });    
  }
  
  renderStepContentText(label) {
    const str = " Click Here to Track >>> ";
    return (
      <div>
        <Typography>{label.bigText}</Typography>
        <Typography variant="caption">
          {label.smallText +
            moment(label.timestamp_utc).format("MM-DD") +
            " @ " +
            moment(label.timestamp_utc).format("LT")}
          ;
        </Typography>
           {label.img_url && (
            <span>
                <a> {label.img_text} </a>
                <div>
                   <img style={{ width: "80px", height: "60px" }} src={label.img_url}/>
                </div>
           </span>
        )}
      </div>
    );
  }

  renderFromAddress() {
    const suite = this.state.order.sSuite? `${this.state.order.sSuite}, `:"";
    return (
      suite +
      this.state.order.sAddress +
      " " +
      this.state.order.sQuad +
      ", " +
      this.state.order.sCity +
      ", " +
      this.state.order.sProv +
      ", " +
      this.state.order.sPostal +
      ", " +
      this.state.order.sCountry
    );
  }

  renderToAddress() {
    const suite = this.state.order.cSuite? `${this.state.order.cSuite}, `:"";
    return (
      suite +
      this.state.order.cAddress +
      " " +
      this.state.order.cQuad +
      ", " +
      this.state.order.cCity +
      ", " +
      this.state.order.cProv +
      ", " +
      this.state.order.cPostal +
      ", " +
      this.state.order.cCountry
    );
  }

 
  renderDeliveryDetails() {
    return (
      <Grid container>
        <Grid item xs={12} sm={4}>
          <h6>LOCATION</h6>
          <p>{getLocationString(this.state.order.location)}</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h6>BILLING REFERENCE #</h6>
          <p>{this.state.order.CUST_REF}</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h6>YOUR NAME & PHONE</h6>
          <p>{this.state.order.CALLER}</p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h6>CALL IN</h6>
          <p>
            {" "}
            {this.state.order.dtCallIn_display +
              " " +
              this.state.order.timezone}
          </p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h6>DUE BY</h6>
          <p>{this.state.order.dtDCT_display + " " + this.state.order.timezone}</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h6>DELIVERED</h6>
          <p>{getStatusString(this.state.order.Status)}</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h6>SERVICE MINUTES</h6>
          <p>{this.state.order.SER_TIME}</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h6>RATE(BEFORE TAX)</h6>
          <p>
            {" "}
            ${this.state.order.RATE}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <h6>PROOF OF DELIVERY</h6>
          <p>{this.state.order.PROOF_DEL}</p>
        </Grid>
        <Grid item md={6}>
           <h6>GST</h6>
           <p>{this.state.order.GST_RATE }</p>           
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
        <Container maxWidth="false" bgcolor = "#ffffff">
        {/* <PageBanner title="PARCEL STATUS" subheading={"#" + this.state.id} /> */}
            <Grid container style={{marginLeft:'5px', marginTop:'5px'}}>              
              <Grid item xs={12}>
                <Panel>
                  <Grid item container xs={12} style={{alignItems:'center', justifyContent:'center', marginBottom:'5px'}}><Typography  variant="h5"> Order# {this.props.order.jobID}</Typography></Grid>
                   <Grid container>
                    <Grid item container xs={12} lg={4} xl={2} style={{display:'flex', alignItems:'stretch', justifyContent:'stretch'}}>
                      <Grid container className="border-mobile-bottom">
                        <Grid item xs={6} lg={12} style={{display:'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'center'}}>
                          <h6>Status</h6>
                          <h3>{getStatusString(this.state.order.Status)}</h3>
                        </Grid>
                        <Grid item xs={6} lg={12} className="text-mobile-end" style={{display:'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'center'}}>
                          <h6>Tracking Number</h6>
                          {this.state.order.TrackNumber && (
                            <h3>
                              <span>{this.state.order.TrackNumber}</span>{" "}
                              {/* <IconButton  size="small"  aria-label="copy"><CopyIcon color="primary" /></IconButton> */}
                            </h3>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={8} xl={10}>
                      <Stepper  activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.steps.map((label) => (
                          <Step key={label.bigText} completed={label.filled}>
                            <StepLabel>
                              {this.renderStepContentText(label)}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                </Panel>
              </Grid>
              <Grid item xs={12}>
                <Panel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="d-sm-flex">
                        <div className="pe-sm-4">
                          <img  src={logo} alt="Direct-IT Group Courier"
                            style={{ width: "140px", display:"block", marginBottom: "20px" }}
                          /> 
                        </div>
                        <div className="flex-fill">
                          <h6 style={{ paddingTop: '10px' }}>FROM</h6>
                          <p style={{ fontWeight: 500 }}>{this.state.order.sCompanyName}</p>
                          <p>{this.renderFromAddress()}</p>
                          <h6 style={{ paddingTop: '10px' }}>TO</h6>
                          <p style={{ fontWeight: 500 }}>{this.state.order.cCompanyName}</p>
                          <p>{this.renderToAddress()}</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2} style={{paddingTop:"0px"}}>
                        <Grid item xs={4} md={3}>
                          <h6>Pieces</h6>
                          <p>{this.state.order.PIECES}</p>
                        </Grid>
                        <Grid item xs={4} md={3}>
                          <h6>Weight</h6>
                          <p>{this.state.order.WEIGHT} lbs</p>
                        </Grid>
                        <Grid item xs={4} md={3}>
                          <h6>Service Type</h6>
                          <p>{this.state.order.SER_AREA}</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h6>Instructions</h6>
                          <p>{this.state.order.DEL_INST}</p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <h6>Signature</h6>
                          {this.state.order.signatureURL && (
                            <img
                              src={this.state.order.signatureURL}
                              alt="Customer Signature"
                              style={{ display:"block", width:"100%", maxWidth:"320px",maxHeight:"40px" }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <List>
                            <ListItem
                              onClick={this.handleClick}
                              style={{ padding: 0 }}
                            >
                              <ListItemText
                                disableTypography
                                primary={
                                  <h6 style={{margin:"0"}}>
                                    Delivery Details
                                  </h6>
                                }
                              />
                              {this.state.showDeliveryDetails ? (
                                <ExpandLess style={{ color: "#337AB7" }} />
                              ) : (
                                <ExpandMore style={{ color: "#337AB7" }} />
                              )}
                            </ListItem>
                            <Collapse
                              in={this.state.showDeliveryDetails}
                              timeout="auto"
                              unmountOnExit
                            >
                              {this.renderDeliveryDetails()}
                            </Collapse>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Panel>
              </Grid>              
            </Grid>  
      </Container>
    );
  }
}

export default OrderReceiptPanel;
