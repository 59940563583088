import React, {useEffect,useState,useRef}from 'react'
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Box} from '@material-ui/core';
import TextInput from '../newOrder/components/inputFields/TextInput';
import httpRequest from '../HttpService';
import 'animate.css/animate.css';
import {Alert, AlertTitle} from '@material-ui/lab'
import Copyright from '../Copyright';
import './tracking.css';
import { useNavigate } from 'react-router-dom';


export default function Tracking(props)  {
    const navigate = useNavigate();
    const {trackingNumber} = useParams();    
    const [type, setType] = useState(props.type);
    const [trackAccount, setTrackAccount] = useState('');   
    const [trackReference, setTrackReference] = useState('');
    const [trackNumber, setTrackNumber] = useState('');
    const [successinfo, setSuccessinfo] = useState('none');
   

    const doTracking = (event) => {
        console.log("do Tracking.");       
        let search={};
        if(trackNumber!=""){
           search ={trackingNumber : trackNumber};
        }
        else{
            search ={
                cust_ref : trackReference,
                trackAccount : trackAccount
            };
        }
        httpRequest.orderSearch("",search).then((body) =>{
             console.log(JSON.stringify(body.data));
             console.log("data lenth is " + body.data.length);
            if(body.data.length>0){
                let order = body.data[0];
                navigate(`/trackOrderDetail/${order.jobID}/${order.ACCOUNT}`);
                //window.location.href='/trackOrderDetail/' + order.jobID + '/' + order.ACCOUNT;
            }
            else
               setSuccessinfo("block")             
       });
    }    
    /*
    useEffect(() => {       
        if(type === "trackNo"){
            let search={};
            search ={trackingNumber : trackingNumber};
            console.log("do Tracking search-" + JSON.stringify(search));
            httpRequest.orderSearch("",search).then((body) =>{
                console.log(JSON.stringify(body.data));
                console.log("data lenth is " + body.data.length);
                if(body.data.length>0){
                   let order = body.data[0];
                   navigate(`/trackOrderDetail/${order.jobID}/${order.ACCOUNT}`);
                   //window.location.href='/trackOrderDetail/' + order.jobID + '/' + order.ACCOUNT;
                }
                else
                  setSuccessinfo("block") 
          });
        }
    });
    */
    
    return (
        <Container  class="tracking">
            <div class={"tracking-wrapper"}>
            <div className={"tracking-wrapper-inner"}>
            <Grid 
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
                <Grid item md={12}>
                   <h2 class={"text-primary text-uppercase title-small"}> Track Your Order </h2>
                </Grid>
                <Grid item md={12}>
                    <TextInput
                      label = "Track Account"
                      type="text"
                      inputType="text"
                      name="trackAccount"                      
                      labelSize = {5}    
                      value={trackAccount}     
                      onChange={(e)=>{setTrackAccount(e.target.value)}}       
                    />
                </Grid>
                <Grid item md={12}>
                    <TextInput
                      label = "Track Reference"
                      type="text"
                      inputType="text"
                      name="trackReference"
                      labelSize = {5} 
                      value={trackReference}  
                      onChange={(e)=>{setTrackReference(e.target.value)}}                        
                    />
                </Grid>
                <Grid item md={12}>
                    <TextInput
                      label = "Track Number"
                      type="text"
                      inputType="text"
                      name="trackNumber"
                      labelSize = {5} 
                      value={trackNumber} 
                      onChange={(e) => {setTrackNumber(e.target.value)}}                        
                    />
                </Grid>   
                <Grid item md={12} style={{width:"120px", textAlign:"center"}}>
                        <Button
                           style={{width:"100%", display:'flex', justifyContent:'space-between',marginTop:'20px'}}
                           variant="contained"                           
                           color="primary" 
                           startIcon={<SearchIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)'}}/>}
                           onClick={doTracking}
                           disabled={(trackNumber === "") && ((trackReference==="") || (trackAccount==="")) }
                         >
                         Track
                         </Button>
                </Grid> 
                <Grid item md={12} style={{marginTop:"80px"}}>
                    <Box display={successinfo}>
                    <Alert severity="warning">
                    <AlertTitle>Search Results 0.</AlertTitle>
                    <strong></strong>                   
                   </Alert>
                   </Box>
                </Grid>                 
            </Grid> 
            </div>
            </div>
          
            { localStorage.getItem("IsEmbedded")!==null && localStorage.getItem("IsEmbedded") ==='yes' ? <></> :<footer>          
              <Copyright /> </footer>}
        </Container>
    );   
}



