import 'date-fns';
import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import styles from './styles';

function RatioWithDateSelect(props) {
  const [value, setValue] = useState('optionOne');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleChange = (event) => {
    console.log("RatioWithDateSelect -" + event.target.value);
    setValue(event.target.value);
    props.onChange({
      target: {
        name: props.name+"Checked",
        value: event.target.value === "optionTwo"
      }
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.onChange({
      target: {
        name: props.name,
        value: date,
      }
    });
  };

  return (
    <div>
      <Typography variant = 'h6' color="primary">{props.header}</Typography>
      <RadioGroup value={value} onChange={handleChange} style={styles.radio}>
        <FormControlLabel value="optionOne" control={<Radio color='primary'/>} label={props.optionOne}/>
        <FormControlLabel value="optionTwo" control={<Radio color='primary'/>} label={props.optionTwo}/>
      </RadioGroup>

      {value === 'optionTwo' &&   
        <div  class="animate__animated animate__fadeInUp">      
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="left">
          <KeyboardDateTimePicker
            inputVariant="outlined"
            variant="inline"
            ampm={true}
            minutesStep={5}
            size="small"
            value={selectedDate}
            onChange={handleDateChange}
            onError={console.log}
            openTo="hours"
            disablePast
            format="yyyy/MM/dd HH:mm"
            style={styles.dateTimePicker}
            KeyboardButtonProps={{
              'aria-label': 'change date-time',
            }}
          />
          </Grid>
        </MuiPickersUtilsProvider>
        </div>
      }
    </div>
  );
}

export default RatioWithDateSelect;
