import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';


const styles = {
  root: {
    display: 'flex',
  },
  formControlSelect: {
    minWidth: 170,
    paddingRight: 20,
    paddingBottom: 16,
  },
  formControlTextfield: {
    width: 115,
    paddingRight: 20,
    paddingBottom: 16,
  }
};

function PackageInfo(props) {
  const [pieces, setPieces] = useState(props.pieces);
  const [weight, setWeight] = useState(props.weight);
  const [packageOptionList, setPackageOptionList] = useState(props.packageOptionList);
  const [uniqueDescList, setUniqueDescList] = useState([]);
  


   useEffect(() => {
    //console.log("props.packageOptionList==" + props.packageOptionList.length);   
    const key = 'fillDescr';
    let arrayUniqueByKey = [];
    arrayUniqueByKey =[...new Map(props.packageOptionList.map(item =>
      [item[key], item])).values()];
    //console.log("arrayUniqueByKey==" + arrayUniqueByKey.length);  
    setUniqueDescList(arrayUniqueByKey);
  
   },[props.packageOptionList]);

   useEffect(() => {
    try{
      if(packageOptionList.length>0){
        packageOptionList.forEach((obj) => {
          if(obj.buttonText === props.packageSize){
            setPieces(obj.fillPieces);
            props.onChange({
              target:{
                 name: "pieces",
                 value:obj.fillPieces
              }
              });        
            setWeight(obj.fillWeight); 
            props.onChange({
              target:{
                name: "weight",
                value:obj.fillWeight
              }
            });   
          }
        });        
      }
    }
    catch(e){
      console.log( "useEffect handleSizeChange exception  " + e); 
    }
  
  },[props.packageSize]);


  const handleWeightChange = (event) => {
    setWeight(event.target.value);
    if(event.target.value < 0){
      setWeight(0);
    }
    if(event.target.value.toString().length>5){
      let temp = event.target.value.toString().substring(0, 5);
      setWeight(parseInt(temp));
    }

  }

  const handlePiecesChange = (event) => {
    setPieces(event.target.value);
    if(event.target.value < 0){
      setPieces(0);
    }
    if(event.target.value.toString().length>3){
      let temp = event.target.value.toString().substring(0, 3);
      setPieces(parseInt(temp));
    }

   
  }

 
  const handlePackageChange = (event) => {
     console.log("handlePackageChange-" + event.target.name + "--" + event.target.value);
     props.onChange({
      target:{
        name: "packageDesc",
        value:event.target.value
      }
    });  



    let selectedPackageOption =  uniqueDescList.filter(function( obj ) {
      return obj.fillDescr === event.target.value;
    });

    setPieces(selectedPackageOption[0].fillPieces);
    props.onChange({
      target:{
         name: "pieces",
         value:selectedPackageOption[0].fillPieces
      }
      });

    setWeight(selectedPackageOption[0].fillWeight); 
    props.onChange({
      target:{
        name: "weight",
        value:selectedPackageOption[0].fillWeight
      }
    });  
    
    props.onChange({
      target:{
        name: "packageSize",
        value:selectedPackageOption[0].buttonText
      }
    }); 

    props.onChange({
      target:{
        name: "packageImage",
        value:selectedPackageOption[0].buttonImage
      }
    }); 
  }

  //packageType
  return (
    <Tooltip title="Refine even more" placement="top" arrow>
    <div>
      <Typography variant = 'h6' color="primary">Package Info</Typography>
      <FormControl variant="outlined" style={styles.formControlSelect}>
        <InputLabel>Size</InputLabel>
        <Select
          value={props.packageDesc}
          onChange={handlePackageChange}
          label="Size"
          name="packageDesc"
        >
          {uniqueDescList.map((option) => (
            <MenuItem value={option.fillDescr}>{option.fillDescr}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={styles.formControlTextfield}>
        <InputLabel>Pieces</InputLabel>
        <Input
          name="pieces"
          value={pieces}
          onBlur={props.onChange}
          onChange={handlePiecesChange}          
          type="number"
          startAdornment={
            <InputAdornment position="start">
              #
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl style={styles.formControlTextfield}>
        <InputLabel>Weight</InputLabel>
        <Input
          name="weight"
          value={weight}
          onBlur={props.onChange}
          onChange={handleWeightChange}
          type="number"
          startAdornment={
            <InputAdornment position="start">
              lbs
            </InputAdornment>
          }
        />
      </FormControl>

    </div>
    </Tooltip>

    
  );
}

export default PackageInfo;