import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PackageOptions from './PackageOptions';
import styles from './styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';


const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: 0,
        border: 0,
        direction: "horizontal",
        flexWrap: 'wrap',
    }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles({
    root: {
        padding: 0,
        textTransform: "none",
        // width: "100%",
        "& img": {
            border: '2px solid #e5e5e5',
            borderRadius: '7px',
            height: '100px',
        },
        "&:hover": {
            "& img": {
                color: "rgb(26, 88, 159)",
                border: "2px solid #337AB7"
            }
        },
    },
    selected: {
        "& img": {
            color: "rgb(26, 88, 159)",
            border: "2px solid #337AB7",
        }
    }
})(ToggleButton);

const theme = createTheme({
    palette: {
        action: {
            active: "#EEEEEE"
        },
        text: {
            primary: "#EEEEEE"
        }
    }
});
function AllPackageOptions(props) {
    const [packageSize, setSize] = React.useState("");
    const [packageOptionList, setPackageOptionList] = useState(props.packageOptionList); 

    const handleSize = (event, newSize) => {
        //console.log("handleSize-" + newSize);
        setSize(newSize);
        props.onChange({
            target: {
                name: "packageSize",
                value: newSize,
            }
        });

        let selectedPackageOption =  packageOptionList.filter(function( obj ) {
            return obj.buttonText === newSize;
          });          
        props.onChange({
            target:{
               name: "packageDesc",
               value:selectedPackageOption[0].fillDescr
            }
        });

        props.onChange({
            target:{
              name: "packageImage",
              value:selectedPackageOption[0].buttonImage
            }
          });
    };

    return (
        <div style={{ maxWidth: '500px' }}>
            <Typography variant='h6' color="primary">Package</Typography>
            <ThemeProvider theme={theme}>

                <StyledToggleButtonGroup
                    size="small"
                    value={props.packageSize}
                    exclusive
                    onChange={handleSize}
                    style={styles.root}
                >
                    {packageOptionList.map((option) => (
                        <StyledToggleButton value={option.buttonText} disableRipple>
                            <PackageOptions packageType={option.buttonText} image={option.buttonImage}/>
                        </StyledToggleButton>
                    ))}
                </StyledToggleButtonGroup>
            </ThemeProvider>

            <div style={{ paddingBottom: '20px' }} />
        </div>
    );
}

export default AllPackageOptions;
