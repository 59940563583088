import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Card, CardContent, CardHeader } from "@material-ui/core";
import { Typography } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { palette } from '../../palette';
import CustomIconButton from './inputFields/CustomIconButton';
import { PropaneSharp } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: palette.primary.light,
        textAlign: 'center',
        width: '100%',
        height: '120px'
    },
    button: {
        margin:0,
    },
    icon: {
        color: palette.primary.main,
        opacity: 0.1,
        padding: 2,
        transform: 'scale(2)'
    }

  }));

export default function CameraButton(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [galleryOpen, setGallery] = React.useState(false);
  
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const openGallery = (event) => {
        console.log("openGallery");
        props.onChange(!galleryOpen);
        setGallery(!galleryOpen);
    }
  
    return (
        <Card className={classes.root}>
            <CardContent>
                <CameraAltIcon className={classes.icon}/>
                <br/>
                {/* <Typography variant='button'color="primary" style={{margin:4,fontSize:12}}>{props.images? props.images.length : 0} Images</Typography> */}
                <Typography variant='button'color="primary" style={{margin:1,fontSize:7}}> {props.title}</Typography>
                <br/>
                <CustomIconButton icon={galleryOpen?"CloseSharpIcon":"AddOutlinedIcon"} tooltip={props.tooltip} label="Attach Files" onClick={openGallery} variant="contained" color="primary" selected={galleryOpen} rippledisabled/>
            </CardContent>
        </Card>
    );
}