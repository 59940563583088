import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import styles from './styles';
import TextInput from '../inputFields/TextInput';
import CustomIconButton from '../inputFields/CustomIconButton';
import SelectInput_ORG from '../inputFields/SelectInput_org';

import CustomDialog from '../dialog/Dialog';
/** 
    Caller Input (two icons oh left side)
    takes in props
    @inputType - either text or select
    @multiline - if text input is multiline
    @optionList - options if select inputType
    @value - value displayed by the input
    @onChange - onChange handler
    @label - label (title) of the input
    @labelSize - 1-12 how much of the width is the label vs the input
    @color - default is primary <-blue could choose secondary (ie red)
    @iconOne- icon if there is an icon
    @iconTwo- icon if there is an icon
    @onIconClick - when icon is clicked
**/


function CallerInput(props) {

    const [openDialog, setOpenDialog] = useState(false);

    const toggleDialog = () => {
        console.log('=========toggleDialog===============' );
        setOpenDialog(!openDialog);
    };



    const confirmHandler = () => {
        toggleDialog();
        props.onIconTwoClick();
    };


    function renderInput() {        
        if(props.inputType === "select"){
            return (
                <SelectInput_ORG
                    optionList = {props.optionList}
                    value = {props.value}
                    onChange = {props.onChange}
                    label = {props.label}
                    labelSize={props.labelSize}
                    color={props.color}
                    {...props}/>
            );
        }
        if(props.inputType === "text"){
            return (
                <TextInput
                    type={props.type}
                    label={props.label}
                    id={props.name}
                    labelSize={props.labelSize}
                    inputLength = {props.inputLength}                  
                    variant="outlined"
                    size="small"
                    color={props.color}
                    onChange = {props.onChange}
                    {...props}/>    
            );
        }
    }     

    return (
        <div>            
            <Grid item container  direction='row' 
                //justifyContent='flex-end' 
                justifyContent='stretch'
                alignItems='center' 
                sm={12} 
                style={{margin:'0', width:'100%' }} 
                spacing={0}>
                <Grid item sm={9}>
                    {renderInput()}
                </Grid>
                <Grid item sm={3}>
                    <span>
                    <CustomIconButton
                        icon={props.iconOne}
                        tooltip={props.icononetooltip}
                        onClick={props.onIconOneClick}
                        color={props.color}/>
                    {(props.inputType === "select" && props.value !== 'select' && props.value !== '') &&<CustomIconButton
                        icon={props.iconTwo}
                        tooltip={props.icontwotooltip}
                        onClick={toggleDialog}
                        color={props.color}/> }
                     {(props.inputType === "text") &&<CustomIconButton
                        icon={props.iconTwo}
                        tooltip={props.icontwotooltip}
                        onClick={props.icontwotooltip}
                        color={props.color}/> }    
                    <CustomDialog
                       open={openDialog}
                       onClose={toggleDialog}
                       dialogTitle = 'Warning'
                       dialogMessage = 'Caller will be permanently removed from the list!'
                       dialogAction={confirmHandler}
                    />    
                    </span>
                </Grid>              
            </Grid>
        </div>

    );
  
}

export default CallerInput;