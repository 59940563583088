import React from 'react';
import './whitePanel.css'

const WhitePanel = props => {

    return (
        <div className="panel">
            {props.children}
        </div>
    );

};
export default WhitePanel;
