import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import OrderDetailsBox from './orderDetailsBox';
import moment from 'moment';

const OrderInfo = (props) => {

    const [custRef, setCustRef] = useState("");
    const [intructions, setInstructions] = useState("");
    const callIn=  props.state.rate?.dtCallIn;
    const dueBy= props.state.rate?.dtdct;
    const fromName= props.state.pickUpLocation.companyName;
    const fromAddress= props.state.pickUpLocation.address +
        (props.state.pickUpLocation.quad
        ? ", " + props.state.pickUpLocation.quad
        : "");    
    const toName=props.state.deliveryLocation.companyName;
    const toAddress= props.state.deliveryLocation.address +
        (props.state.deliveryLocation.quad
        ? ", " + props.state.deliveryLocation.quad
        : "");  

    useEffect(() => {
        if (props.references.hasOwnProperty('0')) 
            setCustRef(props.references['0']);
        else{
            for(let key in props.references) {
                if(props.references.hasOwnProperty(key)) {
                    setCustRef(props.references[key]);
                    break;
                }
            }
        }    

        // for (let key in props.references) {
        //     if (props.references.hasOwnProperty(key)) {
        //         if(key === '0')
        //           setCustRef(props.references[key]);                              
        //     }
        // }

    },[props.references])

    useEffect(() => {
            console.log("props.signatureRequired-" + props.state.signatureRequired);
            console.log("props.pictureRequired-" + props.state.pictureRequired);
            let inst = props.state.instructions;
            // if(props.signatureRequired) {
            //     inst = "Signature required " + inst;
            // }
            // if(props.pictureRequired) {
            //     inst = "Picture required " + inst;
            // }
            setInstructions(inst);
    },[props.state])        


    function renderButtonImage() {
        if(props.state.packageImage){            
                var binary = '';
                var bytes = new Uint8Array(props.state.packageImage.data);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                  binary += String.fromCharCode( bytes[ i ] );
                }
                const base64String = btoa(binary);
                const imageSrc= "data:image/png;base64,"+ base64String;
                return imageSrc;            
        }        
    }

    function renderRateImage() {
        if(props.state.rate.buttonImage){ 
            const base64String = btoa(String.fromCharCode(...new Uint8Array(props.state.rate.buttonImage.data)));
            const imageSrc= "data:image/svg+xml;base64,"+ base64String;
            return imageSrc;   
        }        
    }

    function getRate() {
        var num = 0;
        if(props.state.rate) {
            num += props.state.rate.Rate;
        }
        if(props.state.returnTripService){
            num += props.state.returnTripService.Rate;
        }
        return +num.toFixed(2);
    }

    // function getIntructions() {
    //         let instructions = props.state.instructions;
    //         if(props.state.GridsignatureRequired) {
    //             instructions = "Signature required " + instructions;
    //         }
    //         if(props.state.pictureRequired) {
    //             instructions = "Picture required " + instructions;
    //         }
    //         return instructions;
    // }


    var callInUTC = moment.utc(callIn).toDate();
    var callInLocal = moment(callInUTC).local().format('YYYY-MM-DD HH:mm');

    var dueByUTC = moment.utc(dueBy).toDate();
    var dueByLocal = moment(dueByUTC).local().format('YYYY-MM-DD HH:mm');

    return (
        <div>
            <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            style={styles.orderContainer}
            >
                <Grid item sm={4}>
                 <OrderDetailsBox title="Your Name" value={props.state.name} bold={true}/>
                </Grid>
                <Grid item sm={4}>
                 <OrderDetailsBox title="Call In" value={callInLocal} bold={true}/>
                </Grid>
                <Grid item sm={4}>
                 <OrderDetailsBox title="Due By" value={dueByLocal} bold={true}/>
                </Grid>

                {/* next row */}
                <Grid item sm={4}>
                 <OrderDetailsBox title="From" value={fromName} bold={true}/>
                </Grid>
                <Grid item sm={8}>
                 <OrderDetailsBox title="From Address" value={fromAddress} bold={true}/>
                </Grid>

                {/* next row */}
                <Grid item sm={4}>
                 <OrderDetailsBox title="To" value={toName} bold={true}/>
                </Grid>
                <Grid item sm={8}>
                 <OrderDetailsBox title="To Address" value={toAddress} bold={true}/>
                </Grid>
                            
                {/* next row */}
                <Grid item sm={3}>
                 <OrderDetailsBox title="Pieces" value={props.state.pieces} bold={true}/>
                </Grid>
                <Grid item sm={3}>
                 <OrderDetailsBox title="Weight" value={props.state.weight + ' lb'} bold={true}/>
                </Grid>
                <Grid item sm={3}>
                   <OrderDetailsBox title="Rate" value={'$'+ getRate()} bold={true}/>
                </Grid>
                <Grid item sm={3}>                 
                   <OrderDetailsBox title="Billing Ref#" value={custRef} bold={true}/>
                </Grid>    
                {/* next row */}
                <Grid item sm={2}>                    
                    {/* <img src={renderButtonImage()} style={{width:50, height:50}}/>                       */}
                    <div style={styles.cardTitleRow}>
                            <div style={styles.cardTitleIcon}>
                                <img src={renderButtonImage()} style={{width:50, height:50}}/>
                            </div>
                            <Typography style={{fontSize: 14, fontWeight: 600}}>
                                   {props.state.packageSize}
                            </Typography>
                    </div>
                </Grid>
                <Grid item sm={2}>
                   {props.state.rate && props.state.rate.buttonImage && 
                        <div style={styles.cardTitleRow}>
                            <div style={styles.cardTitleIcon}>
                                <img src={renderRateImage()} style={{width:50, height:50}}/>
                            </div>
                            <Typography style={{fontSize: 14, fontWeight: 600}}>
                                   {props.state.rate.buttonText1}
                            </Typography>
                        </div>
                   }
                </Grid>
                <Grid item sm={8}>                 
                   {/* <OrderDetailsBox title="Instruction" value={getIntructions()} bold={true}/> */}
                   <OrderDetailsBox title="Instruction" value={intructions} bold={true}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default OrderInfo;