import axios from 'axios';

const base_url = 'https://wdstripeapi.azurewebsites.net';
// const base_url = 'http://localhost:4242';

const config = {
    headers: { 
    'api_key': 'a0aed364-9738-4585-a7a0-72e1675f10f7', 
    'Content-Type': 'application/json'

    }
};

class StripeRequest {

    createCheckoutSession = (a, m) => {
        console.log('sending request to create checkout session ' + a);
        const data = JSON.stringify({"amount": a, "metadata": m});
        console.log(data);
        return axios.post(base_url + '/create-checkout-session',data,config);
    }

    checkCheckoutSession = (sessionId) => {
        console.log('sending request to check checkout session ' + sessionId);
        return axios.get(base_url + '/checkout-session?sessionId='+sessionId, config);
    }
    
    checkPaymentIntent = (paymentIntent) => {
        console.log('sending request to check payment intent ' + paymentIntent);
        return axios.get(base_url + '/payment-intent?paymentIntent='+paymentIntent, config);
    }

    
}

const stripeRequest = new StripeRequest();

export default stripeRequest;