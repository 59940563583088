import React, { } from 'react';
import { Container} from '@material-ui/core';
import Copyright from './Copyright';


export default function WDWebview(props) {  

    const IsEmbbed = localStorage.getItem('IsEmbedded')!==null && localStorage.getItem('IsEmbedded')==='yes';   
    return (
            <Container maxWidth="lg" class="NewOrder">
                <iframe
                    width="100%"
                    height="1150px"
                    allow="microphone *"
                    src={ props.url}
                />   
                {!IsEmbbed &&<footer>
                    <Copyright/>
                </footer>}
            </Container>            
    );
    
}


