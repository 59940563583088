import React from 'react';
import  { useEffect } from 'react';
import { Container} from '@material-ui/core';
import OrdersList from './ordersList';
import Copyright from '../Copyright';



export default function OrderStatus(props) {  

   
    useEffect(() => {   
        const loadScript = (callback) => {
            const tidioScript = document.createElement("script");
            tidioScript.src = "//code.tidio.co/c9qnm0vk1h183dspvaeg2nir0zq8ixfh.js";
            tidioScript.id = "tidioscript";
            window.document.body.appendChild(tidioScript);
            //document.getElementById("tidio-chat-iframe").style.visibility = 'visible';
            callback();
        };
 
        loadScript(() => {
            const tidioChatIframe = document.getElementById("tidio-chat-iframe");
            if (tidioChatIframe) {
               tidioChatIframe.style.display = 'inline';  // Setting the display style as needed
               console.log('Tidio chat iframe is now visible');
            } else {
                console.log('Tidio chat iframe not found at the time of script load.');
            }
        });
 
        // return () => {
        //     const removeScript = (callback) => {     
        //         console.log("Script removing");
        //         const scriptElement = document.getElementById("tidio-chat");
        //         if (scriptElement && scriptElement.parentNode) {
        //             //scriptElement.parentNode.removeChild(scriptElement);
        //             document.getElementById("tidio-chat-iframe").style.display='none';
        //             console.log('Script removed',document);  
        //         }
        //     };
        //     removeScript(() => {
        //         console.log("Script removed");
        //     });
        // };
    }, []);
     


    const IsEmbbed = localStorage.getItem('IsEmbedded')!==null && localStorage.getItem('IsEmbedded')==='yes';
    const account = localStorage.getItem('current_accountNumber')!== '' ? localStorage.getItem('current_accountNumber'):localStorage.getItem('accountNumber');  
  
    return (
        <div className='container_orders-list'>
        <Container maxWidth="false">  
            <div style = {{ height: "100%", width: "100vw" }}>              
                <OrdersList account={account} isEmbedded={IsEmbbed}/>  
            </div>
            {!IsEmbbed && <footer><Copyright/></footer>}
        </Container>    
        </div>  
    );
   

}

