const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  TextInputGrid: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
  },
  formControlSelect: {
    width: 350,
    paddingBottom: 16,
  },
  formControlTextfield: {
    width: 350,
    paddingBottom: 16,
  },
  formControlAddName: {
    width: 292,
    paddingBottom: 16,
  },
  optionBox: {
    marginRight: '10px',
    marginBottom: '10px',
  },
  optionCaption: {
    display: 'flex',
    justifyContent: 'center',
    color: '#000000',
  }
};

export default styles;