import React, { useState, useEffect, useRef } from 'react';
import {Button, ButtonGroup, ListItem, List, Tooltip} from '@material-ui/core';
import PanelItem from './PanelItem';
import './AddressPanel.css';
import httpRequest from '../../../HttpService';
import AddressForm from '../addressForm';
import CustomInput from '../inputFields/CustomInput';


const AddressPanel = (props) => {

    //Init google maps
    const [googleAPILoaded, setGoogleAPILoaded] = useState(false);

    useEffect(() => {
        const loadGoogleMapScript = (callback) => {
            if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
                console.log("window google or window google maps")
                callback();
            } else {
                const googleMapScript = document.createElement("script");
                googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
                window.document.body.appendChild(googleMapScript);
                console.log("creating event listening google map")
                googleMapScript.addEventListener("load", callback);
            }
        }

        loadGoogleMapScript(() => {
            console.log("google api script loaded")
            setGoogleAPILoaded(true);
        });
    }, []);

    const [addrList, setAddrList] = useState([]);   //addressList
    const [addrState, setAddrState] = useState(false);  //addrState is loaded
   
    
    //Handle address selection
    const [selectedAddrIndex, setSelectedAddrIndex] = useState(props.initialSelected);

    const handleListItemClick = (index) => {
        console.log('Address selected - index', index);
        console.log('Address selectedAddrIndex is ', selectedAddrIndex);
        //if(index !== selectedAddrIndex){
           setSelectedAddrIndex(index);       
           //signal change
           props.onChange(addrList[index], props.type);    
        //}  
    };
 
  

    //Handle address query
    const [query, setQuery] = useState("");
    const handleSearch = (event) => {
        console.log('=========handleSearch - query ='+ event.target.value );
        setQuery(event.target.value);
        setSelectedAddrIndex("");  
        
    }
    

     //Handle address form
     const [showForm, setShowForm] = useState(false);
     const handleClick = (event) => {        
            setShowForm(!showForm); 
     };
    

    //Handle new address added
    const [newAddress, setNewAddress] = useState('');
    function handleNewAddress(newAddress) {
        setNewAddress(newAddress);
        console.log('New Address added', newAddress);
        setAddrState(addrList.unshift(newAddress)); //add new address to top of address list
        setSelectedAddrIndex(0); //reset selected address index
        props.onChange(newAddress, props.type);
    }



    //Handle address removal
    const [removedAddressIndex, setRemoveAddressIndex] = useState('');
    function handleRemoveAddress(removedAddressIndex) {
        setRemoveAddressIndex(removedAddressIndex);
        console.log('Removed Address - index', removedAddressIndex);
        setAddrState(addrList.splice(removedAddressIndex, 1)); //remove address from address list
        setSelectedAddrIndex(''); //reset selected address
    }
    

    //For searching existing addresses
    useEffect(() => {
        console.log('=========useEffect ============query is'  + query);
        async function getAddr() {
            if(!props.accountNumber) return;
            const addressList=[];
            httpRequest.getTopAddressByType(props.accountNumber, {$: query}, props.type).then(async function(body){
                //console.log('=========useEffect - getTopAddressByType ='+ JSON.stringify(body.data) );
                var userAddress = localStorage.getItem('userAddress');    
                var defaultAddress = null;
                if(userAddress !== ""){
                    defaultAddress = JSON.parse(userAddress);                        
                 }           
                await body.data.forEach((value) => {
                    if(defaultAddress==null || value.addressID != defaultAddress.addressID )
                        addressList.push(value);
                });
                if(defaultAddress && query.length==0)
                    addressList.unshift(defaultAddress);


                if(addressList.length > 0) {
                    const listWithID = [];
                    var currentID = 0;
                    addressList.map(item => {
                        listWithID.push({
                            ...item,
                            id: currentID
                        });
                        currentID = currentID + 1;
                    });
                    await setAddrList(listWithID);
                    
                    setAddrState(true);
                    if(props.type === "shipper" && query === "") {
                        setSelectedAddrIndex(0);
                        props.onChange(addressList[0], props.type, true);
                        // var pre_orderStr = localStorage.getItem('previousOrder');
                        // if(pre_orderStr && pre_orderStr!== ''){
                        //     console.log("retrieved previousOrder is " + pre_orderStr);                    
                        //     var pre_order = JSON.parse(pre_orderStr);
                        //     console.log("retrieved previousOrder name is " + pre_order.from); 
                        //     const index = listWithID.findIndex((obj) => obj.addressID === pre_order.from.addressID);   
                        //     if(index>=0){
                        //         setSelectedAddrIndex(index);
                        //         props.onChange(listWithID[index], props.type, true); 
                        //     }  
                        //     else{
                        //         setSelectedAddrIndex(0);
                        //         props.onChange(listWithID[0], props.type, true);
                        //     }
                        // }
                        // else{
                        //     setSelectedAddrIndex(0);
                        //     props.onChange(listWithID[0], props.type, true);
                        // }
                    }
                } else {
                    expandSearchAddr();
                }
            });
        }

        async function expandSearchAddr() {
            if(!props.accountNumber) return;
            const expandedSearchAddressList=[];
            httpRequest.getExpandedAddress(props.accountNumber,{$: query}, 0).then(async function(body){
                await body.data.forEach((value) => {
                    expandedSearchAddressList.push(value);
                });

                if(expandedSearchAddressList.length > 0) {
                    await setAddrList(expandedSearchAddressList);
                    setAddrState(true);
                    if(props.type === "shipper" && query === "") {
                        setSelectedAddrIndex(0);
                        props.onChange(expandedSearchAddressList[0], props.type, true);
                    }
                } else {
                    setShowForm(true);
                }
                
            });
        }

        if(!showForm) {
            getAddr();
        }
    },[props.accountNumber, query]);


    //autocomplete search
    const placeInputRef = useRef(null);
    const [newAddressValues, setNewAddressValues] = useState(null);
    useEffect(() => {
        if(googleAPILoaded && showForm) {
            initPlaceAPI();
       }
       else{
            if(googleSearchHandler!== null){
               console.log("useEffect remove listener");
              // new window.google.maps.event.clearListeners(googleAutocomplete, "place_changed");
              // setGoogleSearchHandler(null);
              let id = props.type === "consignee" ? "DeliverySearch:":"PickupSearch";
              new window.google.maps.event.clearInstanceListeners(document.getElementById(id));
            }
       }
    }, [googleAPILoaded, showForm]);

    // initialize the google place autocomplete
    const [googleSearchHandler, setGoogleSearchHandler] = useState(null)
    const [googleAutocomplete, setGoogleAutocomplete] = useState(null)

    const initPlaceAPI = () => {
        console.log("init place api wiht listener")

        var geolocation = {
            lat:51.04522,
            lng:-114.06302
        };     

        var circle = new window.google.maps.Circle({center: geolocation, radius: 8000});
        let options = {
             bounds:circle.getBounds()
        };

        let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current, options);
        setGoogleAutocomplete(autocomplete);
        let handler = new window.google.maps.event.addListener(autocomplete, "place_changed", function () {
            let place = autocomplete.getPlace();
            let placeFormatted = convertPlaceToFriendlyObject(place);
            console.log(placeFormatted);
            populateMatchedAddress(placeFormatted);
        });
        setGoogleSearchHandler(handler);
    };

    function convertPlaceToFriendlyObject(place) {
        let result = {};
        if(place) {
            let components = place.address_components;

            for( let i = 0; i < components.length; i++ ) {
                if (i === 0) {
                    result.searchBy = components[i].types[0];
                }
                result[components[i].types[0]] = components[i].long_name;
            }
         }
        result.formattedAddress = place.formatted_address;
        result.lat = place.geometry.location.lat();
        result.lng= place.geometry.location.lng();
        result.name = place.name;
        result.place_id = place.place_id;

        return result;
   }

    function populateMatchedAddress (myAddress) {
        let tempAddressValues = {};
        if(myAddress.route !== undefined){
            if(myAddress.route.endsWith("Southwest")) {
                tempAddressValues.quad = "SW";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Southwest"));
            }
            else if(myAddress.route.endsWith("Southeast")) {
                tempAddressValues.quad = "SE";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Southeast"));
            }
            else if(myAddress.route.endsWith("Northwest")) {
                tempAddressValues.quad = "NW";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Northwest"));
            }
            else if(myAddress.route.endsWith("Northeast")) {
                tempAddressValues.quad = "NE";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route.substring(0, myAddress.route.indexOf("Northeast"));
            }
            else {
                tempAddressValues.quad = "";
                tempAddressValues.address = myAddress.street_number + ' ' + myAddress.route;
            }
        }
        else {
            let arr = myAddress.formattedAddress.split(',');
            if(arr[0].includes('Southwest')) {
                tempAddressValues.quad = "SW";
                tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Southwest'));
            }
            else if(arr[0].includes('Southeast')) {
                tempAddressValues.quad = "SE";
                tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Southeast'));
            }
            else if(arr[0].includes('Northwest')) {
                tempAddressValues.quad = "NW";
                tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Northwest'));
            }
            else if(arr[0].includes('Northeast')) {
                tempAddressValues.quad = "NE";
                tempAddressValues.address = arr[0].substring(0,arr[0].indexOf('Northeast'));
            }
            else {
                tempAddressValues.quad = '';
                tempAddressValues.address = arr[0];
            }
        }

        tempAddressValues.city = myAddress.locality;
        tempAddressValues.country = myAddress.country;
        tempAddressValues.postal = myAddress.postal_code;
        tempAddressValues.lat = myAddress.lat;
        tempAddressValues.long = myAddress.lng;
        tempAddressValues.prov =  myAddress.administrative_area_level_1;
        tempAddressValues.companyName = myAddress.name;

        setNewAddressValues(tempAddressValues);
    }
/*
initPlaceAPI-{"searchBy":"street_number",
"street_number":"3927","route":"Edmonton Trail","neighborhood":"Highland Park","locality":"Calgary","administrative_area_level_1":"Alberta","country":"Canada","postal_code":"T2E 6T1","formattedAddress":"3927 Edmonton Trail, Calgary, AB T2E 6T1, Canada","lat":51.0880436,"lng":-114.0523395,"name":"Queens Breakfast Cocktails","place_id":"ChIJnRu6HN1lcVMRu5w559VgEEY"}
*/

    const predictionsList = () => {
        return (
            <>
            {/* {place && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                <div style={{ marginBottom: 10 }}><b>Selected Place</b></div>
                <div><b>Address:</b> {place.address}</div>
                <div><b>Lat:</b> {place.lat}</div>
                <div><b>Lng:</b> {place.lng}</div>
            </div>} */}
            </>
        );
    }

    
    const listItems = data => {
        const result = data.map((item, index) => (
            <ListItem 
                button 
                className="panel-list-item" 
                divider="true" 
                disabled ={props.pickUpLocation? props.pickUpLocation.addressID== item.addressID? true:false : false}
                selected={selectedAddrIndex === index}
                autoFocus={selectedAddrIndex === index ? true: false}
                onClick={() => {
                    handleListItemClick(index);
                    setNewAddressValues(null);
                    placeInputRef.current.value='';                                      
                }}
                key={`address-list-item-${index}`}                                 
            > 
                <PanelItem                    
                    selected={selectedAddrIndex === index} 
                    data={item} 
                    index={index}
                    type={props.type}
                    key={`address-panel-item-${index}`}
                    onChange = {props.onChange}
                    removedAddressIndex={handleRemoveAddress} 
                    onChangeContact={props.onChangeContact}   
                    accountNumber={props.accountNumber}                                     
                /> 
            </ListItem>
        ));
        return result;
    };

    return(
        <div>
            <Tooltip title="Select the delivery address or type" placement="top" arrow>
            <div>
                <CustomInput
                    type="name"
                    name = {props.type === "consignee" ? "DeliverySearch:":"PickupSearch"}
                    icon="AddOutlinedIcon"
                    inputType="text"
                    label={props.type === "consignee" ? "Delivery Location:":"Pickup Location"}
                    onIconClick={handleClick}
                    labelSize = {5}
                    onChange={handleSearch}
                    inputRef={placeInputRef}
                />
                {predictionsList()}
                {showForm && (
                <AddressForm 
                    accountNumber = {props.accountNumber}
                    title="New Address" 
                    saveButtonLabel="Save" 
                    cancelButtonLabel="Cancel" 
                    variant="add" 
                    cancelButtonOnClick={() => {
                        handleClick();
                        console.log("clean search text")
                        setNewAddressValues(null);
                        placeInputRef.current.value='';
                        setQuery("");
                        /*
                        if(googleSearchHandler!== null){
                            console.log("cancel remove listener");
                            //new window.google.maps.event.clearListeners(googleAutocomplete, "place_changed");
                            //setGoogleSearchHandler(null);
                            let id = props.type === "consignee" ? "DeliverySearch:":"PickupSearch";
                            new window.google.maps.event.clearInstanceListeners(document.getElementById(id));
                        }
                        */
                    }}
                    afterSaveAddress = {() =>{
                        handleClick();
                        setNewAddressValues(null);
                    }}  
                    newAddress={handleNewAddress}
                    data={newAddressValues}
                />
                )}
            </div>

            </Tooltip>
            {addrState &&
            <div className="panel-list" id="addr-form">
                <List className="panel-list-container" disablePadding={true} key="addr-list"  alignItems="flex-start">
                    {listItems(addrList)}
                </List>
            </div>
            }
        </div>
    )
}

export default AddressPanel;


//https://stackoverflow.com/questions/58169983/scroll-to-selected-list-item-in-material-ui

//https://stackoverflow.com/questions/33049322/no-way-to-remove-google-places-autocomplete