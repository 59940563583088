import React, {useEffect,useState}from 'react'

import Map, { Marker, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Pin from './Pin';

import httpRequest from '../../../HttpService';
/*
import endIcon from './images/end48.png';
import startIcon from './images/start48.png';
import driverIcon from './images/vand49.png';
import signatureIcon from './images/SigPin.png';
import cameraIcon from './images/CameraPin_M.png'
*/


import endIcon from "./images/end_pin_sm.png";
import startIcon from "./images/start_pin_sm.png";
import driverIcon from "./images/pin_driver_sm.png";
import signIcon from "./images/pin_sign_sm.png";
import cameraIcon from "./images/pin_photo_sm.png";



const MapboxPanel = props => {
    //console.log("props.orderID is" + props.orderID);
       
    const [shPosition, setShPosition] = useState(null)
    const [cPosition, setCPosition] = useState(null)
    const [drPosition, setDrPosition] = useState(null)
    const [sigPosition, setSignPosition] = useState(null)
    const [zoom, setZoom] = useState(14)
    const [camPositions, setCamPositions] = useState([])

    useEffect(() => {
        if (props.orderID) {
            var res;
            httpRequest.getJobGPS(props.orderID).then(response => {
                //console.log("Get GPS-" + JSON.stringify(response.data));
                setShPosition({lat:response.data.slat, lng:response.data.slong});
                setCPosition({lat:response.data.clat, lng:response.data.clong});
                var dist = distance(response.data.slat,response.data.slong,response.data.clat,response.data.clong);
                //console.log("distance-" + dist);
                if(dist < 1)
                   setZoom(20);
                else if(dist < 2)
                   setZoom(15);
                else if(dist < 10)
                   setZoom(12); 
                else if(dist < 50)
                   setZoom(10); 
                else
                   setZoom(9);                
                if(response.data.DriverLat !== null){
                    setDrPosition({lat:response.data.DriverLat, lng:response.data.DriverLong});
                }
                else 
                    setDrPosition(null);

                if(response.data.TripLocation && !response.data.TripLocation.includes("Delivered")){        
                    const interval = setInterval(() => {
                        httpRequest.getJobGPS(props.orderID).then(response => {
                            //console.log("Get GPS-" + JSON.stringify(response.data));
                            if(response.data.slat != null)
                               setShPosition({lat:response.data.slat, lng:response.data.slong});
                            if(response.data.clat != null)   
                               setCPosition({lat:response.data.clat, lng:response.data.clong});
                            if(response.data.DriverLat !== null){
                                setDrPosition({lat:response.data.DriverLat, lng:response.data.DriverLong});
                            }
                            else 
                                setDrPosition(null);
                            if(response.data.TripLocation.includes("Delivered")) {
                                console.log("order delivered, stop poll gps." );
                                clearInterval(interval);  
                            }    
                        });
                    }, 30000);
                    return () => clearInterval(interval);
                }   
            });  
            
            if(props.order.delLat !== null && props.order.delLong!== null)
               setSignPosition({lat:props.order.delLat, lng:props.order.delLong});
            if(props.photoList && props.photoList.length>0) {
                props.photoList.forEach((img) => { 
                    console.log(img.gpsPoint?.points[0].x + '--' + img.gpsPoint?.points[0].y);
                });
            }  

        };
    },[props.data]);
    

    function distance(lat1, lon1, lat2, lon2) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1/180;
            var radlat2 = Math.PI * lat2/180;
            var theta = lon1-lon2;
            var radtheta = Math.PI * theta/180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;
    
            dist = dist * 1.609344;            
            return dist;
        }
    }

    return(cPosition !== null) ?(
      <Map
        initialViewState={{
                longitude: cPosition.lng,
                latitude: cPosition.lat,
                pitch: 85,
                zoom: 10
            }}
            style={{width: "100%", height: 480}}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            //mapboxAccessToken={"pk.eyJ1IjoiZGFycnlsd3UiLCJhIjoiY2t6MXF4eWQ2MW9sbDJvbXc1djlpd2tyNiJ9.-HSTj2T629xIl2NwM4UTZA"}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        >

        <Marker 
            longitude={shPosition.lng}
            latitude={shPosition.lat}
        >
            <Pin pin_icon= {startIcon}/>
       </Marker>   

        <Marker 
            longitude={cPosition.lng}
            latitude={cPosition.lat}
        >
            <Pin pin_icon= {endIcon}/>
       </Marker>   

       { drPosition != null &&
             <Marker 
               longitude={drPosition.lng}
               latitude={drPosition.lat}
             >
             <Pin pin_icon= {driverIcon}/>
        </Marker>  
        }

        { sigPosition && sigPosition != null &&
             <Marker 
               longitude={sigPosition.lng}
               latitude={sigPosition.lat}
             >
             <Pin pin_icon= {signIcon}/>
        </Marker>  
        }

        {props.photoList.map((item) => (
                item.gpsPoint && item.gpsPoint.points[0] &&item.gpsPoint.points[0].x && item.gpsPoint.points[0].y &&
                <Marker 
                  longitude={parseFloat(item.gpsPoint.points[0].y)}
                  latitude ={parseFloat(item.gpsPoint.points[0].x)}
                >
                  <Pin pin_icon= {cameraIcon}/>
                </Marker>  
        ))}
          
    </Map>
    ) : <></>;

};
export default MapboxPanel;
