import { palette } from "../../../palette";
const styles = {
    root: {
        display: 'flex',
    },
    
    labelWrapper: {
        backgroundColor:  palette.primary.light,
        borderRadius: '.25rem 0rem 0rem .25rem',
        border: '1px solid ' + palette.primary.light,
        textAlign: 'center',
        padding: '.35rem',
    },
    labelWrapperSecondary: {
        backgroundColor:  palette.error.light,
        borderRadius: '.25rem 0rem 0rem .25rem',
        border: '1px solid ' + palette.primary.light,
        textAlign: 'center',
        padding: '.35rem',
    },

    label: {
        paddingTop: '.05rem',
        color: palette.primary.dark,
    },

    labelSecondary: {
        paddingTop: '.05rem',
        color: palette.error.dark,
    },
    
    /*** field Input ***/
    
    formControlTextfield: {  
        display: 'flex',
        borderRadius: '0rem .25rem .25rem 0rem',
        backgroundColor: 'white' ,
    },
    
    formControlSelect: {
        display: 'flex',
        borderRadius: '0rem .25rem .25rem 0rem',
        backgroundColor: 'white' ,
    },
    
}

export default styles;

